import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

interface LanguageSelectorProps {
  currentLanguage: string;
  setCurrentLanguage: (language: string) => void;
  languageOptions: string[];
  darkMode?: boolean;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  currentLanguage,
  setCurrentLanguage,
  languageOptions,
  darkMode = false,
}) => {
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setCurrentLanguage(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      sx={{
        marginLeft: 2,
        "& .MuiOutlinedInput-root": {
          color: darkMode ? "#fff" : "#000",
          backgroundColor: darkMode ? "rgba(255, 255, 255, 0.1)" : "#f5f5f5",
          border: `1px solid ${darkMode ? "rgba(255, 255, 255, 0.3)" : "#ccc"}`,
          borderRadius: 1,
          transition: "background-color 0.3s, border-color 0.3s",
          "&:hover": {
            backgroundColor: darkMode
              ? "rgba(255, 255, 255, 0.2)"
              : "rgba(0, 0, 0, 0.04)",
            borderColor: darkMode ? "#fff" : "#888",
          },
          "&.Mui-focused": {
            borderColor: darkMode ? "#fff" : "#555",
          },
        },
        "& .MuiSelect-icon": {
          color: darkMode ? "#fff" : "#555",
        },
        "& .MuiList-root": {
          backgroundColor: darkMode ? "#424242" : "#fff",
        },
      }}
    >
      <Select
        value={currentLanguage}
        onChange={handleLanguageChange}
        displayEmpty
        inputProps={{ "aria-label": "Language selector" }}
      >
        {languageOptions.map((language) => (
          <MenuItem value={language} key={language}>
            {language.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
