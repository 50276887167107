import { Chip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";

import { useRegistry } from "./context/RegistryContext";
import { SurveyConfiguration } from "../../common/api-client/or-api";
import { reportColourTypes, statusTypes } from "../../types/typesReportV2";
import style from "../online-reporting/ReportV2List.module.scss";

const createColumns = (fields: GridColDef[]) => {
  return fields.map((f: GridColDef) => ({
    sortable: false,
    ...f,
  }));
};

const generateRowData = (
  submission: any,
  registryForms: SurveyConfiguration[],
) => ({
  ...submission,
  time_updated: new Date(submission.time_updated),
  time_created: new Date(submission.time_created),
  time_submitted: submission.time_submitted
    ? new Date(submission.time_submitted)
    : new Date(submission.time_created),
  tracking_number: `${submission.tracking_number ?? ""} v${submission.latest_version}`,
  registry_title: registryForms
    ? registryForms.find(
        (form) => form.survey_class === submission.survey_class,
      )?.name
    : "",
});

const RegistryListView = ({
  reports,
  loadingEntries,
}: {
  reports: any[];
  loadingEntries: boolean;
}) => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { registryForms } = useRegistry();

  const fields: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <Chip
            style={{
              opacity:
                params.value === "rejected" || params.value === "approved"
                  ? 0.6
                  : 1,
            }}
            label={
              statusTypes[params.value]
                ? statusTypes[params.value]
                : params.value
            }
            color={
              reportColourTypes[params.value]
                ? reportColourTypes[params.value]
                : "default"
            }
          />
        );
      },
    },
    {
      field: "tracking_number",
      headerName: "Tracking Number",
      width: 150,
    },
    {
      field: "registry_title",
      headerName: "Registry Title",
      width: 200,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
    },
    {
      field: "time_updated",
      headerName: "Last Updated",
      width: 200,
      type: "dateTime",
    },
    {
      field: "time_submitted",
      headerName: "Time Submitted",
      width: 200,
      type: "dateTime",
    },
  ];

  const columns = createColumns(fields);
  const rows = reports.map((report) => generateRowData(report, registryForms));

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      autoPageSize
      rowSelection={false}
      sx={{
        "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
          {
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
      }}
      loading={loadingEntries}
      onRowClick={(row) => {
        navigate(`/rubiregistry/${orgId}/${row.row.survey_class}/${row.id}`);
      }}
      getRowClassName={() => style.reportRow}
    />
  );
};

export default RegistryListView;
