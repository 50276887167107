import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSessionStorage } from "@react-hooks-library/core";
import { APIProvider } from "@vis.gl/react-google-maps";
import React, { useRef, useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";

import RegistryCard from "./RegistryCard";
import RegistryDetailsPanel from "./RegistryDetailsPanel";
import RegistryListView from "./RegistryListView";
import RegistryMapView from "./RegistryMapView";
import { useRegistry } from "./context/RegistryContext";
import { useDevEnvironment } from "../../DevContext";
import { SubmissionSummary } from "../../common/api-client/or-api";
import { APP_GOOGLE_PLACES_KEY } from "../../environment";
import SearchBar from "../alerts/Search/SearchBar";
import ReportFilters, {
  IReportListFilterOptions,
  RenderReportFilterChips,
} from "../online-reporting/Search/ReportFilters";
import { OnlineReportingContext } from "../online-reporting/context";

const RegistryEntries = () => {
  const { orgId, registryName } = useParams();
  const { registryForms } = useRegistry();
  const [registryTitle, setRegistryTitle] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [newEntryModalOpen, setNewEntryModalOpen] = useState(false);
  const { isDevEnv } = useDevEnvironment();
  const {
    fetchLatestReports,
    reports,
    searchText,
    setSearchText,
    fetchReportConfigurations,
    registryTypeNames,
  } = useContext(OnlineReportingContext);
  const [registrySubmissions, setRegistrySubmissions] = useState<
    SubmissionSummary[]
  >([]);
  const [view, setView] = useSessionStorage("registryView", "list");

  // Simplified filters for registry entries
  const [filters, setFilters] = useSessionStorage<IReportListFilterOptions>(
    "registryFilters",
    {
      creationStartDate: null,
      creationEndDate: null,
      updateStartDate: null,
      updateEndDate: null,
      reportStatus: null,
      trackingNumber: null,
      reportType: null,
      assignedTo: null,
    },
  );

  const searchBarRef = useRef<HTMLInputElement | null>(null);

  const updateSearch = (updatedFilters: IReportListFilterOptions) => {
    setFilters(updatedFilters);
  };

  useEffect(() => {
    if (registryTypeNames) {
      setFilters({
        ...filters,
        reportType: registryTypeNames.join(","),
      });
    }
  }, [registryTypeNames]);

  // useEffect(() => {
  //   if (registryForms) {
  //     const registry = registryForms.find(
  //       (survey) => survey.survey_class === registryName,
  //     );
  //     if (registry) {
  //       setRegistryTitle(registry.name);
  //     }
  //   }
  // }, [registryForms, registryName]);

  useEffect(() => {
    async function fetchData() {
      setLoadingEntries(true);
      if (!initialized) {
        fetchReportConfigurations(orgId ?? "");
      }
      try {
        await fetchLatestReports!(
          orgId ?? "",
          filters.creationStartDate ?? undefined,
          filters.creationEndDate ?? undefined,
          filters.updateStartDate ?? undefined,
          filters.updateEndDate ?? undefined,
          filters.reportStatus
            ? decodeURIComponent(filters.reportStatus)
            : undefined,
          filters.trackingNumber ?? undefined,
          undefined,
          0,
          filters.reportType
            ? decodeURIComponent(filters.reportType)
            : undefined,
          searchText,
        );
        setLoadingEntries(false);
      } catch (e: any) {
        setLoadingEntries(false);
        const message = e.message;
        toast.error(
          `Failed to fetch registry entries${isDevEnv ? `: ${message}` : ""}`,
        );
      }
    }

    fetchData();
    setInitialized(true);
  }, [filters, searchText, registryTitle]);

  useEffect(() => {
    setRegistrySubmissions(
      reports.filter((report) =>
        registryTypeNames.includes(report.survey_name ?? ""),
      ),
    );
  }, [reports, filters]);

  const renderFilters = () => {
    return (
      <ReportFilters
        isOpen={filtersVisible}
        anchorEl={searchBarRef.current}
        onClose={() => setFiltersVisible(false)}
        filters={filters}
        setFilters={updateSearch}
        registryFilters={true}
      />
    );
  };

  const [selectedEntry, setSelectedEntry] = useState<
    SubmissionSummary | undefined
  >();
  const [detailsPanelOpen, setDetailsPanelOpen] = useState(false);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<
    string | undefined
  >();

  const handleEntryClick = (entry: SubmissionSummary) => {
    setSelectedEntry(entry);
    setSelectedSubmissionId(entry.id);
    setDetailsPanelOpen(true);
  };

  const NewEntryModal = () => {
    const [selectedRegistry, setSelectedRegistry] = useState("");

    const handleConfirm = () => {
      if (selectedRegistry) {
        const form = registryForms.find(
          (form) => form.name === selectedRegistry,
        );
        window.location.href = `/rubiregistry/${orgId}/${form?.survey_class}/new`;
      }
    };

    return (
      <Dialog
        open={newEntryModalOpen}
        onClose={() => setNewEntryModalOpen(false)}
      >
        <DialogTitle>Select Registry Type</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Registry Type</InputLabel>
            <Select
              value={selectedRegistry}
              onChange={(e) => setSelectedRegistry(e.target.value)}
              label="Registry Type"
            >
              {registryTypeNames.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewEntryModalOpen(false)}>Cancel</Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            disabled={!selectedRegistry}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div style={{ padding: "8px 16px", display: "relative" }}>
      <NewEntryModal />
      <div
        style={{
          position: "absolute",
          right: "16px",
          top: "16px",
          width: "100px",
          zIndex: 10,
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => setNewEntryModalOpen(true)}
          variant="contained"
          startIcon={<AddIcon />}
        >
          New
        </Button>
      </div>
      <SearchBar
        getMoreItems={() => {}}
        onSearch={() => {}}
        onClearSearch={() => {}}
        searchView={view}
        setSearchView={setView}
        defaultView="map"
        searchText={searchText}
        setSearchText={setSearchText}
        currentItems={reports}
        setCurrentItems={() => {}}
        filtersVisible={filtersVisible}
        setFiltersVisible={setFiltersVisible}
        totalAlerts={reports.length}
        loading={loadingEntries}
        setLoading={setLoadingEntries}
        searchType="report"
        filtersRef={searchBarRef}
        filterChips={
          <RenderReportFilterChips
            filters={filters}
            setFilters={updateSearch}
          />
        }
        activeViews={["list", "card", "map"]}
      />
      {filtersVisible && renderFilters()}
      {view === "list" && (
        <div
          style={{
            height: "calc(100vh - 180px)",
            width: "100%",
          }}
        >
          <RegistryListView
            reports={registrySubmissions}
            loadingEntries={loadingEntries}
          />
        </div>
      )}
      {view === "card" && (
        <div
          style={{
            height: "calc(100vh - 180px)",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <Grid container spacing={2}>
            {registrySubmissions.map((report, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={report.id || index}
                sx={{
                  display: "flex",
                  "& > *": {
                    // This targets the RegistryCard component
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    "& .MuiCardContent-root": {
                      // Target the card content
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end", // Push content to bottom
                    },
                  },
                }}
              >
                <RegistryCard
                  index={index}
                  registryItem={report}
                  onCardClick={handleEntryClick}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {view === "map" && (
        <div
          style={{
            height: "calc(100vh - 180px)",
            width: "100%",
          }}
        >
          <APIProvider apiKey={APP_GOOGLE_PLACES_KEY}>
            <RegistryMapView
              reports={registrySubmissions}
              loadingEntries={loadingEntries}
              onPinClick={handleEntryClick}
              selectedReportId={selectedSubmissionId}
            />
          </APIProvider>
        </div>
      )}
      <RegistryDetailsPanel
        open={detailsPanelOpen}
        onClose={() => {
          setDetailsPanelOpen(false);
          setSelectedSubmissionId(undefined);
        }}
        registryItem={selectedEntry}
      />
    </div>
  );
};

export default RegistryEntries;
