import { SurveyModel } from "../../../common/api-client/or-api";
import { ILocaleObject } from "../types";

export const extractSurveyTitleFromModel = (model: SurveyModel) => {
  if (typeof model.configuration === "string") {
    const parsedConfig = JSON.parse(model.configuration);
    return parsedConfig.title || model.id;
  }
  if (typeof model.configuration === "object") {
    const title = model.configuration.title;
    if (typeof title === "string") {
      return title;
    } else if (typeof title === "object") {
      return `${title.default} / ${title.fr}`;
    }
  }

  return model.id;
};

export const extractLocaleStringFromField = (
  field: string | ILocaleObject,
  locale?: string,
) => {
  if (typeof field === "string") {
    return field;
  }
  if (typeof field === "object") {
    if (locale === "fr") {
      return field.fr;
    }
    return field.default;
  }
};
