import {
  Analytics,
  Edit,
  Group,
  PageviewOutlined,
  PostAdd,
  PrivacyTip,
  Upload,
} from "@mui/icons-material";
import { IconButton, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { MenuItemLink, useSidebarState, useTheme } from "react-admin";
import { useTranslation } from "react-i18next";

import { useDevEnvironment } from "../../DevContext";

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const RubiBoloMenu: FC<Props> = () => {
  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const [open, setOpen] = useSidebarState();
  const { t } = useTranslation("bulletin/menu");
  const hideSideBar = () => {
    setOpen(false);
  };
  const { isDevEnv } = useDevEnvironment();

  return (
    <div className="menu">
      {open ? (
        <div className="sidebar-top">
          <div className="app-name">
            Rubi<span>ALERT</span>{" "}
          </div>
        </div>
      ) : (
        <>
          <div className="application-item">
            <Tooltip title="Bulletin Management System" placement="right" arrow>
              <IconButton
                color="secondary"
                aria-label="RubiAlert App"
                size="large"
                disableRipple
                sx={{ backgroundColor: "lightgrey" }}
              >
                <PrivacyTip />
              </IconButton>
            </Tooltip>
          </div>
        </>
      )}
      <div className="left-menu">
        <MenuItemLink
          to={`/rubialert/alerts`}
          primaryText={t("bulletinBoard")}
          leftIcon={<PageviewOutlined />}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        <MenuItemLink
          to={`/rubialert/add-alert`}
          primaryText={t("addNew")}
          leftIcon={<PostAdd />}
          onClick={(e) => {
            if (isSmall) hideSideBar();
          }}
        />
        <MenuItemLink
          to={`/rubialert/upload-alert`}
          primaryText={t("upload")}
          leftIcon={<Upload />}
          onClick={() => {
            if (isSmall) hideSideBar();
          }}
        />
        <MenuItemLink
          to={`/rubialert/drafts`}
          primaryText={t("drafts")}
          leftIcon={<Edit />}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        <MenuItemLink
          to={`/rubialert/analytics`}
          primaryText={t("dashboard")}
          leftIcon={<Analytics />}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        <MenuItemLink
          to={`/rubialert/manage-groups`}
          primaryText={t("groups")}
          leftIcon={<Group />}
          onClick={isSmall ? hideSideBar : () => {}}
        />
      </div>
    </div>
  );
};

export default RubiBoloMenu;
