import { ComponentCollection, surveyLocalization } from "survey-core";
import "survey-core/defaultV2.min.css";
import { ICreatorOptions } from "survey-creator-core";
import "survey-creator-core/survey-creator-core.min.css";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";

import { ADDRESS_AUTOCOMPLETE_TYPE } from "../custom-questions/AddressAutocompleteQuestion";
import { CAMERA_REGISTRY_MAP_TYPE } from "../custom-questions/CameraRegistrySJS/CameraRegistryQuestion";

const FormBuilder = ({
  onSave,
  mode,
  // this is the actual pages, questions, and other survey elements
  surveyJSObject,
}: {
  onSave: (surveyJSObject) => Promise<void>;
  mode: "edit" | "create" | "view";
  surveyJSObject;
}) => {
  const creatorOptions: ICreatorOptions = {
    isAutoSave: true,
    readOnly: mode === "view",
    showPreviewTab: true,
    showDesignerTab: mode === "edit" || mode === "create",
    showJSONEditorTab: mode === "edit" || mode === "create",
    showLogicTab: mode === "edit" || mode === "create",
    showTranslationTab: mode === "edit" || mode === "create",
    showState: mode === "edit" || mode === "create",
  };

  const creator = new SurveyCreator(creatorOptions);

  const supportedLocales = ["en", "fr"];

  const addressAutoCompleteButton =
    ComponentCollection.Instance.getCustomQuestionByName(
      "custom-address-autocomplete",
    );

  if (!addressAutoCompleteButton) {
    ComponentCollection.Instance.add({
      name: "custom-address-autocomplete",
      title: "Address Autocomplete",
      questionJSON: {
        type: ADDRESS_AUTOCOMPLETE_TYPE,
        title: "Address Autocomplete",
        isRequired: true,
      },
    });
  }

  const cameraRegistryButton =
    ComponentCollection.Instance.getCustomQuestionByName(
      "custom-camera-registry",
    );

  if (!cameraRegistryButton) {
    ComponentCollection.Instance.add({
      name: "custom-camera-registry",
      title: "Camera Registry",
      questionJSON: {
        type: CAMERA_REGISTRY_MAP_TYPE,
        title: "Camera Registry",
        isRequired: true,
      },
    });
  }

  creator.saveSurveyFunc = function (saveNumber, callback) {
    // Note for later, if the saveNumber continues to interfere with loading, we can:
    // after the survey is saved, we can call the API to get the newly created survey
    // and then reset the saveNumber to 0
    if (!surveyJSObject) {
      return;
    }
    const newSurveyContent = creator.text;
    const getUpdatedSurveyJSObject = () => {
      if (typeof newSurveyContent === "string") {
        return JSON.parse(newSurveyContent);
      } else {
        return newSurveyContent;
      }
    };

    const updatedSurveyJSObject = getUpdatedSurveyJSObject();

    onSave(updatedSurveyJSObject)
      .then(() => {
        console.log(saveNumber);
        callback(saveNumber, true); // Indicate successful save
      })
      .catch((error) => {
        console.error("Error saving survey: ", error);
        callback(saveNumber, false); // Indicate unsuccessful save
      });
  };

  if (surveyJSObject) {
    if (typeof surveyJSObject === "string") {
      creator.text = surveyJSObject;
    } else {
      creator.text = JSON.stringify(surveyJSObject);
    }
  }

  surveyLocalization.supportedLocales = supportedLocales;
  // * creator.onMachineTranslate.add((_, options) => {
  // *   // Prepare strings for Microsoft Translator as an array of objects: [{ Text: "text to translate" }]
  // *   const data = [];
  // *   options.strings.forEach(str => { data.push({ Text: str }); });
  // *   // Include required locales in the URL
  // *   const params = "api-version=3.0&from=" + options.fromLocale + "&to=" + options.toLocale;
  // *   const url = endpoint + "/translate?" + params;
  // *   fetch(url, {
  // *     method: "POST",
  // *     headers: {
  // *       "Content-Type": "application/json",
  // *       "Ocp-Apim-Subscription-Key": apiKey,
  // *       "Ocp-Apim-Subscription-Region": resourceRegion,
  // *       "X-ClientTraceId": crypto.randomUUID()
  // *     },
  // *     body: JSON.stringify(data)
  // *   }).then(response => response.json())
  // *     .then(data => {
  // *       // Convert data received from Microsoft Translator to a flat array
  // *       const translatedStrings = [];
  // *       for (let i = 0; i < data.length; i++) {
  // *         translatedStrings.push(data[i].translations[0].text);
  // *       }
  // *       // Pass translated strings to Survey Creator
  // *       options.callback(translatedStrings);
  // *
  // *     }).catch(error => {
  // *       // If translation was unsuccessful:
  // *       options.callback();
  // *       alert("Could not translate strings to the " + options.toLocale + " locale");
  // *     });
  // * });
  creator.onMachineTranslate.add((_, options) => {
    const data: { Text: string }[] = [];
    options.strings.forEach((str: string) => {
      data.push({ Text: str });
    });
    console.log(data);
  });

  return (
    <SurveyCreatorComponent creator={creator} style={{ height: "100%" }} />
  );
};

export default FormBuilder;
