import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Chip,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

import BottomFixedActionContainer from "../../common/BottomFixedActionContainer";
import StatusActionModal from "../../common/StatusActionModal";
import {
  GetSubmissionResponsePortal,
  GetSurveyResponse,
  OrgConfigModel,
  SubmissionVersionTime,
  Template,
} from "../../common/api-client/or-api";
import { API } from "../../common/api-client/or-api/api";
import { formatDateShort } from "../../common/utils";
import { reportColourTypes, statusTypes } from "../../types/typesReportV2";
import { registerAutocomplete } from "../online-reporting/custom-questions/AddressAutocompleteQuestion";
import { registerCameraRegistryMap } from "../online-reporting/custom-questions/CameraRegistrySJS/CameraRegistryQuestion";
import {
  createCondensedModel,
  ReportAttachment,
} from "../online-reporting/utils";

interface RegistryEntryProps {
  mode?: "view" | "edit" | "new";
}

const RegistryEntry: React.FC<RegistryEntryProps> = ({ mode = "view" }) => {
  const { orgId, registryName, entryId } = useParams();

  // State for submission and survey data
  const [entryMetadata, setEntryMetadata] =
    useState<GetSubmissionResponsePortal>();
  const [surveyResult, setSurveyResult] = useState<GetSurveyResponse>();
  const [surveyModel, setSurveyModel] = useState<Model>();
  const [versionHistory, setVersionHistory] = useState<SubmissionVersionTime[]>(
    [],
  );
  const [selectedVersion, setSelectedVersion] = useState<string>();
  const [reportAttachments, setReportAttachments] = useState<
    Array<ReportAttachment>
  >([]);

  const navigate = useNavigate();

  // Add state for modal
  const [makePublicModalOpen, setMakePublicModalOpen] = useState(false);

  // Add new state for notes
  const [notesModified, setNotesModified] = useState(false);
  const [saveNotesLoading, setSaveNotesLoading] = useState(false);
  const notesRef = useRef<HTMLInputElement>();

  // Add inside the RegistryEntry component, near other state declarations
  const [serviceConfig, setServiceConfig] = useState<OrgConfigModel | null>(
    null,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [emailTemplates, setEmailTemplates] = useState<Template[]>([]);

  // Load initial data
  useEffect(() => {
    async function loadData() {
      if (!entryId) return;

      try {
        // Get submission data
        const submission = await API.submission.getPortal(entryId, "latest");
        setEntryMetadata(submission);
        setVersionHistory(submission.version_times || []);
        setSelectedVersion(submission.latest_version?.toString());

        // Get survey structure
        const survey = await API.survey.get(submission.survey_id);
        setSurveyResult(survey);

        if (mode === "view") {
          const { newSurvey, reportAttachments } = await createCondensedModel(
            survey.survey_pdf,
            submission.submission,
            submission.attachments,
          );
          setSurveyModel(newSurvey);
          setReportAttachments(reportAttachments);
        } else {
          const { newSurvey, reportAttachments } = await createCondensedModel(
            survey.survey,
            submission.submission,
            submission.attachments,
          );
          const newModel = new Model(survey.survey);
          newModel.data = submission.submission;
          setSurveyModel(newModel);
          setReportAttachments(reportAttachments);
        }
      } catch (error) {
        console.error("Error loading entry data:", error);
      }
    }
    registerAutocomplete();
    registerCameraRegistryMap();
    loadData();
  }, [entryId]);

  // Add after other useEffect hooks
  useEffect(() => {
    async function getConfig() {
      const response = await API.orgConfig.get1(orgId ?? "");
      setServiceConfig(response);
    }
    getConfig();
  }, [orgId]);

  // Add useEffect to load email templates
  useEffect(() => {
    async function getTemplates() {
      const response = await API.orgConfig.getTemplate(orgId ?? "");
      setEmailTemplates(response.templates);
    }
    getTemplates();
  }, [orgId]);

  // Handle version changes
  const updateVersion = async (version: string) => {
    if (!entryId) return;

    try {
      const submission = await API.submission.getPortal(entryId, version);
      setEntryMetadata(submission);
      setSelectedVersion(version);

      // Update survey model with new data
      const { newSurvey, reportAttachments } = await createCondensedModel(
        surveyResult?.survey_pdf || {},
        submission.submission,
        submission.attachments,
      );
      setSurveyModel(newSurvey);
      setReportAttachments(reportAttachments);
    } catch (error) {
      console.error("Error loading version:", error);
    }
  };

  // Add modal details
  const modalDetails = {
    approved: {
      title: "Approve Entry",
      description: "Close this entry and mark it as approved.",
      detailsRequired: false,
      detailsText: "Optional notes for approval",
    },
    pending_deletion: {
      title: "Remove Registration",
      description: "Mark this registration for removal.",
      detailsRequired: false,
      detailsText: "Optional notes for removal",
    },
  };

  // Add status update function
  const updateStatus = async (status: string, reason?: string) => {
    try {
      await API.submission.updateStatusPortal(entryId ?? "", {
        status: status,
      });

      if (reason) {
        await API.submission.patchPortal(entryId ?? "", {
          [status === "approved" ? "approve_note" : "reject_note"]: reason,
        });
      }

      setEntryMetadata((prev) => ({
        ...prev!,
        status: status,
      }));

      toast.success("Entry status updated successfully");
      setModalOpen(false);
    } catch (error) {
      toast.error("Failed to update status");
    }
  };

  const restoreRegistration = async () => {
    if (!entryId || entryMetadata?.status !== "pending_deletion") return;

    try {
      await API.submission.updateStatusPortal(entryId, {
        status: "approved",
      });

      setEntryMetadata((prev) => ({
        ...prev!,
        status: "approved",
      }));

      toast.success("Registration restored successfully");
    } catch (error) {
      toast.error("Failed to restore registration");
    }
  };

  // Remove the ActionBar component and add getButtonGroups function
  const getButtonGroups = (): JSX.Element[][] => {
    const leftButtons: JSX.Element[] = [];
    const rightButtons: JSX.Element[] = [];

    // Add Edit button to left side if not rejected
    if (entryMetadata?.status !== "rejected") {
      leftButtons.push(
        <Button
          variant="contained"
          color="info"
          onClick={() => navigate(`./edit`)}
        >
          Edit
        </Button>,
      );
    }

    // Add Make Public button for private registrations
    if (entryMetadata?.private_registration) {
      rightButtons.push(
        <Button
          variant="contained"
          color="warning"
          onClick={() => setMakePublicModalOpen(true)}
        >
          Make Registration Public
        </Button>,
      );
    }

    // Add action buttons based on status
    if (entryMetadata?.status === "submitted") {
      rightButtons.push(
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            setSelectedAction("approved");
            setModalOpen(true);
          }}
        >
          Approve RMS Export
        </Button>,
      );
    }
    if (entryMetadata?.status !== "pending_deletion") {
      rightButtons.push(
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setSelectedAction("pending_deletion");
            setModalOpen(true);
          }}
        >
          Remove
        </Button>,
      );
    } else {
      rightButtons.push(
        <Button variant="contained" color="info" onClick={restoreRegistration}>
          Restore Registration
        </Button>,
      );
    }

    return [leftButtons, rightButtons];
  };

  // Add notes update function
  const updateNotes = async () => {
    if (!entryId) return;

    setSaveNotesLoading(true);
    try {
      await API.submission.patchPortal(entryId, {
        notes: notesRef?.current?.value ?? "",
      });

      setEntryMetadata((prev) => ({
        ...prev!,
        notes: notesRef?.current?.value ?? "",
      }));

      setNotesModified(false);
      toast.success("Notes updated successfully");
    } catch (error) {
      toast.error("Failed to update notes, please try again later");
    }
    setSaveNotesLoading(false);
  };

  if (!entryMetadata || !surveyModel) {
    return (
      <div style={{ margin: "60px auto", textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  // Add modal component before the final return statement
  const makePublicModal = (
    <Dialog
      open={makePublicModalOpen}
      onClose={() => setMakePublicModalOpen(false)}
    >
      <DialogTitle>Make Registration Public?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Making the registration public will notify the contacts of the
          registration that they now have access. Public users will now be able
          to submit updates.
          <br />
          <br />
          Do you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setMakePublicModalOpen(false)}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              await API.submission.patchPortal(entryId ?? "", {
                private_registration: false,
              });
              setEntryMetadata({
                ...entryMetadata,
                private_registration: false,
              });
              toast.success("Registration made public successfully");
              setMakePublicModalOpen(false);
            } catch (error) {
              console.error("Error making registration public:", error);
              toast.error("Failed to make registration public");
            }
          }}
          color="primary"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
        style={{
          marginTop: "24px",
          height: "40px",
          position: "fixed",
          zIndex: 2,
          borderRadius: "0 10px 10px 0",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.30)",
          backgroundColor: "#fff",
        }}
      >
        Back
      </Button>

      <div
        style={{
          maxWidth: "960px",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Typography variant="h5" gutterBottom>
            {mode === "new"
              ? "New Entry"
              : mode === "edit"
                ? "Edit Entry"
                : "View Entry"}
          </Typography>

          <Typography variant="subtitle1">Registry: {registryName}</Typography>
          <Typography variant="subtitle1">Entry ID: {entryId}</Typography>
          <Typography variant="subtitle1">
            Created: {formatDateShort(entryMetadata.time_created)}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            Status:{" "}
            <Chip
              style={{
                opacity:
                  entryMetadata.status === "rejected" ||
                  entryMetadata.status === "approved"
                    ? 0.6
                    : 1,
              }}
              label={
                statusTypes[entryMetadata.status]
                  ? statusTypes[entryMetadata.status]
                  : entryMetadata.status
              }
              color={
                reportColourTypes[entryMetadata.status]
                  ? reportColourTypes[entryMetadata.status]
                  : "default"
              }
            />
          </Typography>
          <Typography variant="subtitle1">
            Access:{" "}
            {entryMetadata.private_registration
              ? "Police Controlled"
              : "Public"}
          </Typography>
        </Paper>

        {/* Internal Notes Section */}
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          {/* Add Incident Number Field */}
          <FormControl fullWidth style={{ marginBottom: "16px" }}>
            <TextField
              id="incidentNumber"
              key={entryMetadata?.incident_number}
              inputRef={notesRef}
              disabled={mode === "view"}
              variant="outlined"
              label="RMS Incident / Occurrence Number"
              defaultValue={entryMetadata?.incident_number ?? ""}
              helperText={
                serviceConfig?.metadata.automated_incident_number_generation
                  ? "Auto-generated if left blank; otherwise, provide a number to override"
                  : ""
              }
              placeholder={
                serviceConfig?.metadata.automated_incident_number_generation
                  ? `Auto-generated when the record is ${serviceConfig.metadata.incident_generation_outcomes?.join(", ")}`
                  : "Enter Incident Number"
              }
              onChange={() => {
                if (!notesModified) {
                  setNotesModified(true);
                }
              }}
            />
          </FormControl>

          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Internal Private Notes
          </Typography>

          {/* Existing Notes TextField */}
          <TextField
            fullWidth
            multiline
            minRows={4}
            inputRef={notesRef}
            defaultValue={entryMetadata?.notes ?? ""}
            onChange={() => {
              if (!notesModified) {
                setNotesModified(true);
              }
            }}
            variant="outlined"
          />
          <LoadingButton
            variant="contained"
            color="info"
            size="medium"
            fullWidth
            sx={{ mt: 2 }}
            onClick={updateNotes}
            loading={saveNotesLoading}
            disabled={!notesModified}
          >
            Save Changes
          </LoadingButton>
        </Paper>

        {/* Survey display with version selector */}
        <div
          className={mode === "view" ? "condensed-report-wrapper" : ""}
          style={{ marginBottom: "20px" }}
        >
          <Paper elevation={3} style={{ padding: "20px" }}>
            {/* Version selector moved here */}
            {versionHistory.length > 1 && (
              <FormControl fullWidth style={{ marginBottom: "20px" }}>
                <Typography variant="subtitle1">Version</Typography>
                <Select
                  value={selectedVersion}
                  onChange={(e) => updateVersion(e.target.value)}
                  variant="outlined"
                >
                  {versionHistory
                    .sort((a, b) => b.version - a.version)
                    .map((version) => (
                      <MenuItem key={version.version} value={version.version}>
                        {version.version === entryMetadata.latest_version ? (
                          <span>
                            <strong>Latest Version</strong> -{" "}
                            {formatDateShort(version.time_created)}
                          </span>
                        ) : (
                          <span>
                            Version {version.version} -{" "}
                            {formatDateShort(version.time_created)}
                          </span>
                        )}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <Survey model={surveyModel} />
          </Paper>
        </div>

        <BottomFixedActionContainer buttonGroups={getButtonGroups()} />
        {makePublicModal}
        <StatusActionModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          selectedAction={selectedAction}
          onSubmit={(reason) => updateStatus(selectedAction, reason)}
          modalDetails={modalDetails}
          emailTemplates={emailTemplates}
        />
      </div>
    </>
  );
};

export default RegistryEntry;
