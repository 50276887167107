import { Dialog, Link, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import styles from "./ShareDialog.module.scss";
import { NotifyEmails } from "./add/NotifyEmails";

const ShareDialog = ({
  isShareModalOpenArg,
  setIsShareModalOpenArg,
  bulletinIDs,
}: {
  isShareModalOpenArg: boolean;
  setIsShareModalOpenArg: (arg: boolean) => void;
  bulletinIDs: number[];
}) => {
  const [sharedFlag, setSharedFlag] = useState(false);
  const [sentFlag, setSentFlag] = useState(false);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (sharedFlag && sentFlag) {
      closeShareDialog();
    }
  }, [sharedFlag, sentFlag, setIsShareModalOpenArg]);

  const closeShareDialog = () => {
    setIsShareModalOpenArg(false);
    const newSearchParams = new URLSearchParams(search);
    newSearchParams.delete("share");
    navigate(`${pathname}?${newSearchParams.toString()}`);
  };

  return (
    <Dialog
      open={isShareModalOpenArg}
      onClose={closeShareDialog}
      className={styles.shareDialog}
    >
      <div style={{ minHeight: "40vh", minWidth: "600px" }}>
        {/* <ShareAccess
          bulletinID={bulletinID}
          setSharedFlag={setSharedFlag}
          readOnly={readOnly}
        /> */}
        <NotifyEmails
          bulletinIds={bulletinIDs}
          closeShareDialog={closeShareDialog}
        />
      </div>
    </Dialog>
  );
};

export default ShareDialog;
