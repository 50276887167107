// src/common/StatusActionModal.tsx
import { $convertToMarkdownString } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Info } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

import LexicalEditor, { customTransformers } from "./LexicalEditor";
import { RubiModal } from "./RubiModal";
import { Template } from "../common/api-client/or-api";
import { reportColourTypes } from "../types/typesReportV2";

interface StatusActionModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedAction: string;
  onSubmit: (reason: string) => void;
  modalDetails: {
    [key: string]: {
      title: string;
      description: string | JSX.Element;
      detailsRequired: boolean;
      detailsText: string;
      rteOptionalText?: string;
    };
  };
  emailTemplates?: Template[];
}

const StatusActionModal: React.FC<StatusActionModalProps> = ({
  isOpen,
  onClose,
  selectedAction,
  onSubmit,
  modalDetails,
  emailTemplates = [],
}) => {
  const [toggleTooltip, setToggleTooltip] = useState(false);
  const [specifiedReason, setSpecifiedReason] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const SubmitButton = () => {
    const [editor] = useLexicalComposerContext();
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          editor.update(() => {
            const markdown = $convertToMarkdownString(
              customTransformers,
              undefined,
              true,
            );
            setSpecifiedReason(markdown);
            onSubmit(markdown);
            setIsSubmitting(true);
          });
        }}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        disabled={isSubmitting}
      >
        Submit
      </Button>
    );
  };

  return (
    <RubiModal isModalOpenArg={isOpen} setIsModalOpenArg={onClose}>
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Chip
            color={reportColourTypes[selectedAction]}
            sx={{ marginRight: 1 }}
          />
          <Typography variant="h6">
            {modalDetails[selectedAction]?.title}
          </Typography>
          <IconButton
            onClick={() => setToggleTooltip(!toggleTooltip)}
            size="small"
          >
            <Info />
          </IconButton>
        </Box>
        {toggleTooltip && (
          <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2 }}>
            {modalDetails[selectedAction]?.description}
          </Typography>
        )}

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Autocomplete
            options={emailTemplates}
            getOptionLabel={(option) => option.name}
            value={selectedTemplate}
            onChange={(event, newValue) => {
              setSelectedTemplate(newValue);
              setSpecifiedReason(newValue?.plain_text ?? "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Template"
                variant="outlined"
              />
            )}
          />
        </FormControl>

        <LexicalEditor
          markdown={specifiedReason}
          belowEditorChildren={
            <Typography>
              {modalDetails[selectedAction]?.rteOptionalText}
            </Typography>
          }
          aboveEditorChildren={
            <Typography>{modalDetails[selectedAction]?.detailsText}</Typography>
          }
          editorButtons={[
            <Button
              key="cancel"
              variant="outlined"
              color="secondary"
              onClick={() => {
                setSpecifiedReason("");
                onClose();
                setSelectedTemplate(null);
                setIsSubmitting(false);
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>,
            <SubmitButton key="submit" />,
          ]}
        />
      </Box>
    </RubiModal>
  );
};

export default StatusActionModal;
