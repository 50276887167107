import { Edit, Translate, Language } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  ButtonGroup,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";

import { AttachmentSlider } from "./AttachmentSlider";
import styles from "./CondensedReportView.module.scss";
import { getReportTimeDisplay, getReportTitle, isReportClosed } from "./utils";
import { useDevEnvironment } from "../../../DevContext";
import ExpandedMediaDisplay from "../../../common/ExpandedMediaDisplay";
import { GetSurveyResponse } from "../../../common/api-client/camera-api";
import {
  GetSubmissionResponsePortal,
  SubmissionAttachment,
  SubmissionPortal,
  SubmissionVersionTime,
} from "../../../common/api-client/or-api";
import { API } from "../../../common/api-client/or-api/api";
import { IPoliceServiceConfiguration } from "../../../common/serviceConfigurations";
import { localeToLanguage } from "../../../common/utils";
import { statusTypes } from "../../../types/typesReportV2";
import { PrintPDF } from "../PDFGenerator";
import { modalDetails } from "../constants";
import { OnlineReportingContext } from "../context";
import { extractLocaleStringFromField } from "../form-management/utils";
import {
  createCondensedModel,
  imageUrlToBase64,
  ReportAttachment,
} from "../utils";

/**
 *
 * @param supplementaryPDFStructure - this is basically an array of the pdfs of all the supplementary reports needed for printing
 * @returns
 */
const RenderCondensedReport = ({
  reportMetadata,
  organizationConfig,
  supplementaryPDFStructure,
  childrenSupplementaryReportSurveyJS,
  supplementaryAttachments,
  setSelectedIndex,
  selectedIndex,
  setIsSlideShowActive,
  isSlideShowActive,
}: {
  reportMetadata: GetSubmissionResponsePortal | SubmissionPortal;
  organizationConfig: IPoliceServiceConfiguration;
  supplementaryPDFStructure: { [key: string]: any }[];
  childrenSupplementaryReportSurveyJS: any[];
  supplementaryAttachments: Array<Array<SubmissionAttachment>>;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  selectedIndex: number;
  setIsSlideShowActive: Dispatch<SetStateAction<boolean>>;
  isSlideShowActive: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("reportsv2/condensed_report");
  const isClosed = isReportClosed(reportMetadata);
  const { isDevEnv } = useDevEnvironment();
  const appName = window.location.pathname.split("/")[1];
  const { id, orgId } = useParams();
  const { getSubmissionForID, getSurveyForID } = useContext(
    OnlineReportingContext,
  );
  const [loading, setLoading] = useState(true);
  const [surveyResult, setSurveyResult] = useState<GetSurveyResponse | null>(
    null,
  );
  const [originalReportResponse, setOriginalReportResponse] = useState<{
    [key: string]: any;
  }>({});

  const [reportResponse, setReportResponse] = useState<{
    [key: string]: any;
  }>({});

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isTranslateDialogOpen, setIsTranslateDialogOpen] = useState(false);
  const [reportLatestVersion, setReportLatestVersion] = useState(
    reportMetadata?.latest_version?.toString() ?? "",
  );
  const [surveyModel, setSurveyModel] = useState<any>(new Model());

  const [basePDFStructure, setBasePDFStructure] = useState<{
    [key: string]: any;
  }>({});
  const [attachments, setAttachments] = useState<Array<SubmissionAttachment>>(
    [],
  );
  const [base64Data, setBase64Data] = useState<{ [key: string]: string }>({});
  const [newSurvey, setNewSurvey] = useState<Model>(new Model());

  const [reportAttachments, setReportAttachments] = useState<
    Array<ReportAttachment>
  >([]);
  const [baseSurveyStructure, setBaseSurveyStructure] = useState<any>({});
  const [reportVersionHistory, setReportVersionHistory] = useState<
    SubmissionVersionTime[]
  >([]);
  const [isSupplementaryReport, setIsSupplementaryReport] = useState(false);
  const [showEnglishVersion, setShowEnglishVersion] = useState(true);

  const getSurveyResult = async () => {
    const result = await getSurveyForID(reportMetadata.survey_id);
    if (result) {
      return result;
    } else {
      throw new Error("Survey result is null");
    }
  };

  const getMediaIndexUrl = (attachments: any[], index: number) => {
    console.log(attachments[index].incident_evidence_upload[0].content);
    return reportAttachments[index].incident_evidence_upload[0].content;
  };

  const extraPDFData = {
    incident_type: reportResponse.description ?? "",
    status: statusTypes[reportMetadata.status]
      ? statusTypes[reportMetadata.status]
      : reportMetadata.status,
    tracking_number: reportMetadata.tracking_number ?? "",
    incident_number: reportMetadata.incident_number ?? "",
    time_created: reportMetadata.time_created,
    last_updated_by: reportMetadata.last_updated_by,
    report_officerNotes:
      reportMetadata?.approve_note ??
      reportMetadata?.reject_note ??
      reportMetadata?.escalate_note ??
      "",
  };

  const TranslateSurveyButton = () => {
    return (
      <Tooltip title="Request Translation">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsTranslateDialogOpen(true);
          }}
          endIcon={<Translate />}
          size="small"
        >
          {t("translate")}
        </Button>
      </Tooltip>
    );
  };

  const handleSave = async () => {
    // TODO: Implement save functionality
    console.log("Saving changes...");

    const hasErrors = newSurvey.hasErrors(false, false);
    if (hasErrors) {
      toast.error("Please resolve all errors before saving.");
      return;
    }
    newSurvey.mode = "display";
    setNewSurvey(newSurvey);

    try {
      await API.submission.updateStatusPortal(id ?? "", {
        status: "modification_portal",
      });

      const dataToSend = JSON.parse(JSON.stringify(newSurvey.data));
      const traverse = (obj: any) => {
        for (const key in obj) {
          if (key === "content" && obj["content"]?.startsWith("data:")) {
            delete obj[key];
          } else if (typeof obj[key] === "object") {
            traverse(obj[key]);
          }
        }
      };
      traverse(dataToSend);
      await API.submission.patchPortal(id ?? "", {
        version: {
          submission: dataToSend,
          attachments: { ids: attachments.map((attachment) => attachment.id) },
        },
      });
      await API.submission.updateStatusPortal(id ?? "", {
        status: "submitted",
      });
      location.reload();
    } catch (error) {
      console.error(error);
      await API.submission.updateStatusPortal(id ?? "", {
        status: "submitted",
      });
    }
  };

  const EditButton = () => {
    const isEditMode = newSurvey.mode === "edit";

    return (
      <Tooltip
        title={isEditMode ? "Save changes" : modalDetails.edit.description}
      >
        <Button
          variant="contained"
          color={isEditMode ? "success" : "info"}
          size="small"
          onClick={isEditMode ? handleSave : () => setIsEditDialogOpen(true)}
          endIcon={isEditMode ? null : <Edit />}
        >
          {isEditMode ? "Save" : "Edit"}
        </Button>
      </Tooltip>
    );
  };

  async function updateSurveyOne(submissionVersion: string) {
    setReportLatestVersion(submissionVersion);

    if (getSubmissionForID) {
      const result = await getSubmissionForID(id ?? "", submissionVersion);
      const submission = result?.submission;
      setOriginalReportResponse(submission);
      setReportResponse(submission);

      const localSurvey = new Model(baseSurveyStructure);
      localSurvey.data = submission;
      setSurveyModel(localSurvey);
    }
  }

  useEffect(() => {
    const fetchSurveyResultAndUpdateStates = async () => {
      try {
        const result = await getSurveyResult();
        setSurveyResult(result);

        if (result) {
          setBasePDFStructure(result.survey_pdf ?? {});
          setBaseSurveyStructure(result.survey);

          setAttachments(reportMetadata.attachments ?? []);
          const localSurvey = new Model(result.survey_pdf ?? {});
          const submission = reportMetadata.submission;
          localSurvey.data = submission;
          setOriginalReportResponse(submission);
          setReportResponse(submission);
          setSurveyModel(localSurvey);
          setReportVersionHistory(reportMetadata.version_times ?? []);
          setIsSupplementaryReport(reportMetadata?.is_supplemental ?? false);
        }
      } catch (error) {
        console.error("Failed to fetch survey result:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyResultAndUpdateStates();
  }, [id, reportMetadata]);

  useEffect(() => {
    async function load() {
      const tempData: { [key: string]: string } = {};
      for (const attachment of attachments) {
        if (
          attachment &&
          attachment.presigned_get &&
          attachment.content_type.includes("image")
        ) {
          const base64 = await imageUrlToBase64(
            attachment.presigned_get.s3_presigned.uri,
          );
          tempData[attachment.filename] = base64;
        }
      }
      setBase64Data(tempData);
    }
    load();
  }, [attachments, id]);

  const getTranslatedResponse = (reportMetadata: any, reportResponse: any) => {
    const translationEnLocales = reportMetadata?.translation_en
      ? Object.keys(reportMetadata?.translation_en)
      : [];
    if (translationEnLocales.length > 0) {
      const translatedResponse = JSON.parse(
        JSON.stringify(originalReportResponse),
      );
      for (const locale of translationEnLocales) {
        const translationEn = reportMetadata?.translation_en[locale];
        for (const key in translationEn) {
          translatedResponse[key] = `${translationEn[key]}`;
        }
      }
      return translatedResponse;
    }
  };

  useEffect(() => {
    if (!basePDFStructure.pages || !originalReportResponse || !attachments)
      return;

    async function getData() {
      const translatedResponse = getTranslatedResponse(
        reportMetadata,
        originalReportResponse,
      );
      if (translatedResponse) {
        setReportResponse(translatedResponse);
      }
      const { newSurvey, reportAttachments } = await createCondensedModel(
        basePDFStructure,
        translatedResponse || originalReportResponse,
        attachments,
        true,
        false,
        !isDevEnv,
      );
      setNewSurvey(newSurvey);
      setReportAttachments(reportAttachments);
    }
    getData();
  }, [basePDFStructure, originalReportResponse, attachments, id]);

  const toggleLanguageVersion = async () => {
    if (showEnglishVersion) {
      setReportResponse(originalReportResponse);
      const { newSurvey: updatedSurvey } = await createCondensedModel(
        basePDFStructure,
        originalReportResponse,
        attachments,
        true,
        false,
        !isDevEnv,
      );
      setNewSurvey(updatedSurvey);
    } else {
      const translatedResponse = getTranslatedResponse(
        reportMetadata,
        originalReportResponse,
      );
      setReportResponse(translatedResponse || originalReportResponse);
      const { newSurvey: updatedSurvey } = await createCondensedModel(
        basePDFStructure,
        translatedResponse || originalReportResponse,
        attachments,
        true,
        false,
        !isDevEnv,
      );
      setNewSurvey(updatedSurvey);
    }
    setShowEnglishVersion(!showEnglishVersion);
  };

  if (loading) {
    return (
      <div
        style={{
          margin: "60px auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Paper elevation={3} className={styles.paperContainer}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3 style={{ display: "inline-block", marginRight: "16px" }}>
              {t("incident_report")}
            </h3>{" "}
            {t("version")}: {reportMetadata.latest_version} -{" "}
            {getReportTimeDisplay(
              reportMetadata.time_created,
              reportMetadata.time_updated,
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {/* <ToggleLocaleButton /> */}

            {isDevEnv &&
              (reportMetadata.locale === "en" ||
                reportMetadata.locale === null) && <TranslateSurveyButton />}
            {!isClosed && <EditButton />}
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography id="demo-simple-select-label">
                {t("displaying")}
              </Typography>
              <Select
                id="demo-simple-select"
                value={reportLatestVersion}
                variant="outlined"
                onChange={(event) => updateSurveyOne(event.target.value)}
              >
                {reportVersionHistory
                  .sort((a, b) => b.version - a.version)
                  .map((version) => (
                    <MenuItem
                      key={`version-${version.version}`}
                      value={version.version}
                    >
                      {version.version === reportMetadata.latest_version ? (
                        <span>
                          <strong>{t("latest_version")}</strong> -{" "}
                          {getReportTimeDisplay(version.time_created)}
                        </span>
                      ) : (
                        <span>
                          {t("version")} {version.version} -{" "}
                          {getReportTimeDisplay(version.time_created)}
                        </span>
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isDevEnv &&
              reportMetadata.locale !== "en" &&
              reportMetadata.locale && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "12px",
                    marginTop: "12px",
                    padding: "12px",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ fontStyle: "italic" }}
                  >
                    {showEnglishVersion
                      ? `Auto-translated from: ${localeToLanguage[reportMetadata.locale]}`
                      : `Detected Languages: ${localeToLanguage[reportMetadata.locale]}`}
                  </Typography>
                  <Language color="action" />
                  <ButtonGroup variant="contained" size="small">
                    <Tooltip title="View in English translation">
                      <Button
                        onClick={toggleLanguageVersion}
                        variant={showEnglishVersion ? "contained" : "outlined"}
                        sx={{
                          backgroundColor: showEnglishVersion
                            ? "primary.main"
                            : "transparent",
                          color: showEnglishVersion ? "white" : "primary.main",
                          "&:hover": {
                            backgroundColor: showEnglishVersion
                              ? "primary.dark"
                              : "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        Translated
                      </Button>
                    </Tooltip>
                    <Tooltip title={`View original submission`}>
                      <Button
                        onClick={toggleLanguageVersion}
                        variant={!showEnglishVersion ? "contained" : "outlined"}
                        sx={{
                          backgroundColor: !showEnglishVersion
                            ? "primary.main"
                            : "transparent",
                          color: !showEnglishVersion ? "white" : "primary.main",
                          "&:hover": {
                            backgroundColor: !showEnglishVersion
                              ? "primary.dark"
                              : "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        Original
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </div>
              )}
          </Grid>
        </Grid>
      </div>

      {reportVersionHistory.filter((version) => {
        return version.version === parseInt(reportLatestVersion);
      })[0].modification_request_note && (
        <TextField
          id="notes"
          variant="outlined"
          label={t("modification_request_note")}
          multiline
          className="w-100 my-4"
          minRows={3}
          value={
            reportVersionHistory.filter((version) => {
              return version.version === parseInt(reportLatestVersion);
            })[0].modification_request_note
          }
          disabled={true}
        />
      )}
      <PrintPDF
        basePDFStructure={basePDFStructure}
        report={{ ...reportResponse, ...extraPDFData }}
        attachments={attachments}
        base64Data={base64Data}
        serviceName={organizationConfig?.fullName ?? ""}
        serviceLogoURL={organizationConfig?.logoURL ?? ""}
        reportTitle={getReportTitle(
          isSupplementaryReport,
          extractLocaleStringFromField(baseSurveyStructure?.title, "en") ?? "",
        )}
        hideAdminInformation={false}
        supplementaryReportsObject={{
          supplementaryPDFStructure: supplementaryPDFStructure,
          supplementaryReport: childrenSupplementaryReportSurveyJS,
          supplementaryAttachments: supplementaryAttachments,
        }}
      />
      <div className="condensed-report-wrapper">
        <Survey model={newSurvey} />
      </div>
      {reportAttachments.length > 0 ? (
        <div>
          <Typography
            variant="h6"
            style={{
              marginTop: "20px",
            }}
          >
            {t("attachments")} ({reportAttachments.length})
          </Typography>
          <AttachmentSlider
            reportAttachments={reportAttachments}
            setSelectedIndex={setSelectedIndex}
          />
        </div>
      ) : (
        <Typography
          variant="h6"
          style={{
            marginTop: "20px",
          }}
        >
          {t("no_attachments_present")}
        </Typography>
      )}
      {selectedIndex >= 0 && (
        <ExpandedMediaDisplay
          isSlideShowActive={isSlideShowActive}
          setIsSlideShowActive={setIsSlideShowActive}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          slideshowLength={reportAttachments.length}
          mediaSrc={getMediaIndexUrl(reportAttachments, selectedIndex)}
          mediaType={
            reportAttachments[
              selectedIndex
            ]?.incident_evidence_upload[0].type.includes("image")
              ? "image"
              : "video"
          }
        />
      )}
      <Dialog
        open={isEditDialogOpen}
        onClose={() => {
          setIsEditDialogOpen(false);
        }}
      >
        <DialogTitle>{t("edit_this_report")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("editing_report_note")}</DialogContentText>
          <DialogContentText>
            <br />
            <b>{t("editing_report_note_confirm")}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <Button
            onClick={() => {
              setIsEditDialogOpen(false);
            }}
            color="secondary"
            variant="outlined"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              if (isDevEnv) {
                newSurvey.mode = "edit";
                setNewSurvey(newSurvey);
                setIsEditDialogOpen(false);
              } else {
                navigate(`/${appName}/${orgId}/report/${id}/edit`, {
                  replace: true,
                });
              }
            }}
            color="primary"
            variant="contained"
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isTranslateDialogOpen}
        onClose={() => {
          setIsTranslateDialogOpen(false);
        }}
      >
        <DialogTitle>{t("request_translation")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("request_translation_description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <Button
            onClick={() => {
              setIsTranslateDialogOpen(false);
            }}
            color="secondary"
            variant="outlined"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              const email = "support@tryrubicon.com";
              const subject = "Translation Request";
              const body = `I would like to request a translation for the following report: ${reportMetadata.submission_id}`;
              window.open(`mailto:${email}?subject=${subject}&body=${body}`);
              setIsTranslateDialogOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            {t("request_translation")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default RenderCondensedReport;
