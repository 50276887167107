import { Info } from "@mui/icons-material";
import {
  TextField,
  Chip,
  Box,
  Button,
  Paper,
  Autocomplete,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { batchSendBulletins } from "../../../../common/api-client/apiAlertClient";

const NotifyEmails = ({
  bulletinIds,
  closeShareDialog,
}: {
  bulletinIds: number[];
  closeShareDialog: () => void;
}) => {
  const [sharedEmails, setSharedEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [note, setNote] = useState("");
  const { t } = useTranslation("bulletin/share");

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddEmail = (email: string) => {
    const trimmedValue = email.trim();

    if (!isValidEmail(trimmedValue)) {
      toast.error(t("invalid_email_address"));
      return;
    }

    if (sharedEmails.includes(trimmedValue)) {
      toast.warning(t("duplicate_email"));
    } else {
      setSharedEmails([...sharedEmails, trimmedValue]);
    }
  };

  const handleDelete = (email: string) => {
    setSharedEmails(sharedEmails.filter((v) => v !== email));
  };

  const handleSendEmails = () => {
    if (sharedEmails.length === 0) {
      // check if inputValue is a valid email
      if (isValidEmail(inputValue)) {
        batchSendBulletins(bulletinIds, [inputValue], note);
        setNote("");
        setSharedEmails([]); // Clear the list after sending
        setInputValue("");
        // close the dialog
        closeShareDialog();
        return;
      } else {
        toast.error(t("no_emails_to_send"));
        return;
      }
    } else {
      batchSendBulletins(bulletinIds, sharedEmails, note);
      setNote("");
      setInputValue("");
      setSharedEmails([]); // Clear the list after sending
      toast.success(t("emails_sent_successfully"));

      return;
    }
  };

  return (
    <div className="p-4" style={{ maxWidth: "600px" }}>
      <Accordion elevation={0}>
        <AccordionSummary
          sx={{ padding: "0px" }}
          expandIcon={<Info />}
          // aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>
            <Typography variant="h5" sx={{ width: "100%" }}>
              <b>{t("share_by_email")}</b>
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0px" }}>
          <Typography>{t("sending_emails_explanation")}</Typography>
        </AccordionDetails>
      </Accordion>
      <Autocomplete
        freeSolo
        options={[]} // Specify initial options (if any)
        value={inputValue}
        onChange={(event, newValue) => {
          if (newValue && typeof newValue === "string") {
            handleAddEmail(newValue);
            setInputValue("");
          }
        }}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        filterOptions={(options, params) => {
          const { inputValue } = params;

          if (inputValue.trim() !== "") {
            return [
              ...options,
              t("press_enter_to_add_email", { email: inputValue }),
            ];
          }

          return options;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("add_email")}
            variant="outlined"
            placeholder={t("press_enter_to_add_email", { email: inputValue })}
            fullWidth
            size="small"
            onKeyDown={(event) => {
              if (event.key === "Enter" && inputValue.trim() !== "") {
                event.preventDefault();
                handleAddEmail(inputValue);
                setInputValue("");
              }
            }}
          />
        )}
      />

      {/* text area for note */}
      <TextField
        label={t("note")}
        variant="outlined"
        fullWidth
        size="small"
        rows={3}
        multiline
        value={note}
        onChange={(event) => setNote(event.target.value)}
      />

      <Box mt={1} display="flex" flexWrap="wrap" gap={1}>
        {sharedEmails.map((email, index) => (
          <Chip
            key={index}
            label={email}
            onDelete={() => handleDelete(email)}
            variant="outlined"
          />
        ))}
      </Box>
      <div className="d-flex justify-content-end">
        <Button
          variant="contained"
          color="primary"
          disabled={sharedEmails.length === 0 && !isValidEmail(inputValue)}
          onClick={handleSendEmails}
          sx={{ mt: 2 }}
        >
          {t("send_emails")}
        </Button>
      </div>
    </div>
  );
};

export default NotifyEmails;
