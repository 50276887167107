import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Button,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import { TIME_TYPE } from "../../constants";

const IncidentTimeSelector = ({
  incidentTimes,
  setIncidentTimes,
  errors,
}: {
  incidentTimes: {
    time_type: string;
    start_date: Date | string | null;
    end_date: Date | string | null;
  }[];
  setIncidentTimes: (
    incidentTimes: {
      time_type: string;
      start_date: Date | string | null;
      end_date: Date | string | null;
    }[],
  ) => void;
  errors: any;
}) => {
  const { t } = useTranslation("bulletin/create/datePicker");

  const handleAddTime = () => {
    setIncidentTimes([
      ...incidentTimes,
      { time_type: "exact", start_date: null, end_date: null },
    ]);
  };

  const handleDeleteTime = (index: number) => {
    const updatedTimes = incidentTimes.filter((_, i) => i !== index);
    setIncidentTimes(updatedTimes);
  };

  const handleDateChange = (
    index: number,
    key: "start_date" | "end_date",
    value: Date | null,
  ) => {
    const updatedTimes = [...incidentTimes];
    updatedTimes[index][key] = value;
    console.log(updatedTimes);
    setIncidentTimes(updatedTimes);
  };

  const handleTimeTypeChange = (
    index: number,
    value: string | null | undefined,
  ) => {
    if (value) {
      const updatedTimes = [...incidentTimes];
      updatedTimes[index].time_type = value;
      setIncidentTimes(updatedTimes);
    }
  };

  const dateTimePicker = (
    time: {
      time_type: string;
      start_date: Date | string | null;
      end_date: Date | string | null;
    },
    index: number,
  ) => {
    // I want to convert the time.start_date and time.end_date to Date objects if they are strings
    const startDate =
      typeof time.start_date === "string"
        ? new Date(time.start_date)
        : time.start_date;
    const endDate =
      typeof time.end_date === "string"
        ? new Date(time.end_date)
        : time.end_date;
    switch (time.time_type) {
      case "range":
        return (
          <div style={{ display: "flex", gap: "10px", flexGrow: 1 }}>
            {/* Start Time Picker */}
            <ReactDatePicker
              // if time.start_date is a string, convert it to a date
              selected={startDate}
              className="start-date-picker"
              showTimeSelect
              timeIntervals={15} // Optional: Set time interval
              selectsStart
              timeCaption={t("start_date")}
              dateFormat="Pp"
              maxDate={new Date()}
              placeholderText={t("select_start_date")}
              onChange={(date) => handleDateChange(index, "start_date", date)}
              customInput={
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t("start_date")}
                />
              }
            />

            {/* End Time Picker */}
            <ReactDatePicker
              selected={endDate}
              showTimeSelect
              timeIntervals={15} // Optional: Set time interval
              timeCaption={t("end_date")}
              dateFormat="Pp"
              selectsEnd
              maxDate={new Date()}
              minDate={endDate ?? undefined}
              placeholderText={t("select_end_date")}
              onChange={(date) => handleDateChange(index, "end_date", date)}
              customInput={
                <TextField fullWidth variant="outlined" label={t("end_date")} />
              }
            />
          </div>
        );

      case "time_unknown":
        return (
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => handleDateChange(index, "start_date", date)}
            showTimeSelect={false}
            dateFormat="yyyy/MM/dd"
            wrapperClassName="w-100"
            maxDate={new Date()}
            customInput={
              <TextField
                fullWidth
                variant="outlined"
                label={t("select_date")}
                value={time.start_date}
              />
            }
          />
        );
      case "not_applicable":
      case "unknown":
        return (
          <TextField
            fullWidth
            disabled
            variant="outlined"
            value="No date/time required"
            label="Date/Time"
          />
        );
      default:
        return (
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => handleDateChange(index, "start_date", date)}
            showTimeSelect
            dateFormat="Pp"
            maxDate={new Date()}
            wrapperClassName="w-100"
            customInput={
              <TextField
                fullWidth
                variant="outlined"
                label={t("start_date")}
                value={time.start_date}
              />
            }
          />
        );
    }
  };

  return (
    <div className="w-100">
      {incidentTimes.map((time, index) => (
        <div
          key={index}
          className="w-100"
          style={{
            padding: "10px",
            marginBottom: "10px",
            position: "relative",
          }}
        >
          <Typography variant="subtitle1">
            {t("incident_time", { Index: index + 1 })}
          </Typography>

          <div className="d-flex w-100" style={{ gap: "10px" }}>
            {/* Time Type Selector */}
            <Autocomplete
              value={t(time.time_type)}
              className="w-30"
              options={Object.values(TIME_TYPE)}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t("time_type")}
                />
              )}
              renderOption={(props, option) => (
                <li value={option} {...props}>
                  {t(option)}
                </li>
              )}
              onChange={(e, value) => handleTimeTypeChange(index, value)}
            ></Autocomplete>

            {/* React Datepicker for Start Time */}
            <div className="w-70">{dateTimePicker(time, index)}</div>
            {incidentTimes.length > 1 && (
              <IconButton
                color="secondary"
                title="delete"
                onClick={() => handleDeleteTime(index)}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            )}
          </div>
        </div>
      ))}

      {/* Add Another Time Button */}
      <Button onClick={handleAddTime} color="secondary">
        {t("add_another_date_time")}
      </Button>
    </div>
  );
};

export default IncidentTimeSelector;
