import { AccountCircle, ExitToApp, VpnKey } from "@mui/icons-material";
import AppsIcon from "@mui/icons-material/Apps";
import {
  Box,
  Button,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { forwardRef, useContext, useEffect, useState } from "react";
import { AppBar, UserMenu, useUserMenu } from "react-admin";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./AppBar.module.css";
import { useDevEnvironment } from "../DevContext";
import LanguageSelector from "../common/LanguageSelector";
import { devEnvironment } from "../common/featureFlags";
import { serviceConfigurations } from "../common/serviceConfigurations";
import { AuthStateContext } from "../context";
import teamsIcon from "../images/icons8-microsoft-teams-2019-48.png";

const UserName = forwardRef((props, ref) => {
  const { currentUser, getOrgIDFromURL } = useContext(AuthStateContext);
  const [currentOrg] = useState(getOrgIDFromURL());
  const orgName = Object.values(serviceConfigurations).filter(
    (config) => config.id === currentOrg || config.testID === currentOrg,
  )[0]?.fullName;

  return (
    <>
      <div
        style={{
          padding: "0 16px",
          textAlign: "center",
        }}
      >
        <AccountCircle
          style={{ width: "75px", height: "75px" }}
          color="action"
        />
        <p>{`${currentUser?.first_name ?? ""} ${
          currentUser?.last_name ?? ""
        }`}</p>
        <p>{`${currentUser?.email ?? ""}`}</p>
        <p>{orgName}</p>
      </div>
      <hr />
    </>
  );
});

UserName.displayName = "UserName";

const LogoutButton = forwardRef((props, ref) => {
  const { logout } = useContext(AuthStateContext);
  const handleClick = () => logout();
  return (
    <MenuItem
      onClick={handleClick}
      // ref={ref}
    >
      <ListItemIcon>
        <ExitToApp fontSize="small" color="action" />
      </ListItemIcon>
      <ListItemText>Logout</ListItemText>
    </MenuItem>
  );
});

LogoutButton.displayName = "LogoutButton";

const CustomSettingsMenu = forwardRef((props, ref) => {
  // We are not using MenuItemLink so we retrieve the onClose function from the UserContext
  const { onClose } = useUserMenu();
  const navigate = useNavigate();

  const handleClick = () => {
    onClose();
    navigate("/changepassword");
  };

  return (
    <MenuItem
      // @ts-ignore
      // ref={ref}
      // It's important to pass the props to allow MUI to manage the keyboard navigation
      {...props}
      onClick={handleClick}
    >
      <ListItemIcon>
        <VpnKey fontSize="small" color="action" />
      </ListItemIcon>
      <ListItemText>Change Password</ListItemText>
    </MenuItem>
  );
});

CustomSettingsMenu.displayName = "CustomSettingsMenu";

const ProfileMenu = forwardRef((props, ref) => {
  // We are not using MenuItemLink so we retrieve the onClose function from the UserContext
  const { onClose } = useUserMenu();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthStateContext);

  const handleClick = () => {
    onClose();
    navigate(`/users/${currentUser?.id}/profile`);
  };

  return (
    <MenuItem
      // @ts-ignore
      // ref={ref}
      // It's important to pass the props to allow MUI to manage the keyboard navigation
      {...props}
      onClick={handleClick}
    >
      <ListItemIcon>
        <AccountCircle fontSize="small" color="action" />
      </ListItemIcon>
      <ListItemText>Profile</ListItemText>
    </MenuItem>
  );
});

ProfileMenu.displayName = "ProfileMenu";

const CustomAppBar = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { isDevEnv, toggleDevEnv } = useDevEnvironment();
  const { currentUser, getOrgIDFromURL } = useContext(AuthStateContext);
  const [currentOrg, setCurrentOrg] = useState(getOrgIDFromURL());

  const languageOptions = ["en", "fr"];
  const currentLanguage = i18n.language;
  const setCurrentLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const onServiceChange = (event: SelectChangeEvent<string>) => {
    const serviceID = event.target.value as string;
    setCurrentOrg(serviceID);
    const appName = window.location.pathname.split("/")[1];

    const pathRegex = new RegExp(`${appName}/[a-z0-9-]*/(.*)`);

    const match = pathRegex.exec(window.location.pathname);
    const newURL = `/${appName}/${serviceID}/${match ? match[1] : ""}`;
    window.location.pathname = newURL;
  };

  useEffect(() => {
    setCurrentOrg(getOrgIDFromURL());
  }, [location.pathname]);

  return (
    <AppBar
      {...props}
      alwaysOn
      userMenu={
        <UserMenu>
          <UserName />
          <ProfileMenu />
          <CustomSettingsMenu />
          <LogoutButton />
        </UserMenu>
      }
    >
      <Button
        id="appsButton"
        disabled={currentUser?.profile_update_required ?? false}
        variant="outlined"
        onClick={() => navigate("/")}
      >
        <>
          <AppsIcon /> <span className="topAppsText">Apps</span>
        </>
      </Button>
      {devEnvironment() && (
        <FormControlLabel
          control={<Switch checked={isDevEnv} onChange={toggleDevEnv} />}
          label="Dev Mode"
          style={{ paddingLeft: "20px" }}
        />
      )}
      {currentOrg &&
        currentUser &&
        (currentUser.rubi_org_id === "947cabf8-2f8c-4628-a0df-1440be75908e" ||
          currentUser?.rubi_org_id ===
            "05f95617-2631-47db-8dc8-a272a7076756") && (
          <div>
            <span className={styles.appname}>Viewing as:</span>
            <Select
              value={currentOrg}
              style={{ height: "40px", color: "white" }}
              onChange={onServiceChange}
            >
              {Object.values(serviceConfigurations)
                .filter((config) => config.id)
                .map((config) => (
                  <MenuItem
                    key={config.id}
                    value={devEnvironment() ? config.testID : config.id}
                  >
                    {config.fullName}
                  </MenuItem>
                ))}
            </Select>
          </div>
        )}
      <span className={styles.appname}></span>
      <Typography
        variant="h6"
        color="inherit"
        className={styles.title}
        id="react-admin-title"
      />
      <Tooltip
        title={
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              Join Teams Community
            </Typography>
            <Typography variant="body2">
              Be part of a collaborative space where you can get help desk
              support, share ideas, and learn from other police services using
              Rubicon. Exclusively for Police members only! Click to request to
              join.
            </Typography>
          </Box>
        }
        arrow
      >
        <Button
          id="msTeams"
          onClick={() => {
            const subject = "Joining Teams Community";
            const body = `Hello Rubicon Team,
    
    I would like to join the Teams Community. Please find my details below:
    
    Name: ${currentUser?.first_name ?? ""} ${currentUser?.last_name ?? ""}
    Police Service: [Please specify your Police Service]
    Email tied to your current Teams Account: [Please specify the email address tied to your current Teams Account]
    
    Thank you!
    Best regards, 
    ${currentUser?.first_name ?? ""} ${currentUser?.last_name ?? ""}`;
            window.location.href = `mailto:Support@tryrubicon.com?subject=${encodeURIComponent(
              subject,
            )}&body=${encodeURIComponent(body)}`;
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "8px 16px",
            borderRadius: "4px",
            textTransform: "none",
            color: "white",
            "& .MuiButton-startIcon": {
              marginRight: "8px",
            },
            "& img": {
              height: "30px",
              width: "30px",
              marginRight: "8px",
            },
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
            },
          }}
          className={styles.appName}
        >
          <>
            <img src={teamsIcon} />
            <Typography
              sx={{
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
                fontSize: "1rem",
              }}
            >
              Community
            </Typography>
          </>
        </Button>
      </Tooltip>
      {isDevEnv && !location.pathname.includes("rubireportv2") && (
        <LanguageSelector
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
          languageOptions={languageOptions}
          darkMode={true}
        />
      )}
    </AppBar>
  );
};

export default CustomAppBar;
