import { ArrowBack, Edit } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FormBuilder from "./FormBuilder";
import {
  GetSurveyResponse,
  PutSurveyResponse,
} from "../../../common/api-client/or-api";
import { API } from "../../../common/api-client/or-api/api";
import "./SurveyEdit.scss";

export const SurveyEdit = () => {
  const { orgId, surveyId: surveyIdFromParams } = useParams();
  const navigate = useNavigate();

  const [mode, setMode] = useState<"edit" | "create" | "view">("edit");
  const [surveyId, setSurveyId] = useState<string | null>(null);

  // this has just the surveyJSObject with {survey and survey_pdf}, used in the case where a survey already exists
  const [getSurveyResponse, setGetSurveyResponse] =
    useState<GetSurveyResponse | null>(null);

  // this returns a SurveyModel with the id and configuration, which we need to mo
  const [putSurveyResponse, setPutSurveyResponse] =
    useState<PutSurveyResponse | null>(null);
  const [initialSurveyJSObject, setInitialSurveyJSObject] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      setMode("edit");
      if (surveyIdFromParams) {
        setSurveyId(surveyIdFromParams);
      }
    } else if (surveyIdFromParams) {
      setMode("view");
      setSurveyId(surveyIdFromParams);
    } else {
      setMode("create");
      const putNewSurvey = async () => {
        try {
          const [newSurveyResponse] = await Promise.all([
            API.survey.put(orgId ?? "", {
              survey: {},
            }),
          ]);
          setPutSurveyResponse(newSurveyResponse);
          setInitialSurveyJSObject(newSurveyResponse.survey.configuration);
          setIsLoading(false);
          setSurveyId(newSurveyResponse.survey.id);
        } catch (error) {
          console.error("Error creating new survey: ", error);
        }
      };
      putNewSurvey();
    }
  }, [surveyIdFromParams, window.location.pathname]);

  useEffect(() => {
    if (surveyId) {
      API.survey.get(surveyId).then((response) => {
        setGetSurveyResponse(response);
        setInitialSurveyJSObject(response.survey);
        setIsLoading(false);
      });
    }
  }, [surveyId]);

  const handleSave = async (surveyJSObject) => {
    try {
      await Promise.all([
        API.survey.put(orgId ?? "", {
          survey: {
            id: surveyId,
            configuration: surveyJSObject,
          },
        }),
      ]);
    } catch (error) {
      console.error("Error creating new survey: ", error);
    }
    // navigate(`/rubireportv2/${orgId}/forms/surveys`);
  };

  return (
    <div style={{ height: "100%" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          startIcon={<ArrowBack />}
          onClick={() => navigate(`/rubireportv2/${orgId}/forms/surveys`)}
          sx={{
            marginTop: "8px",
            height: "40px",
            position: "fixed",
            zIndex: 2,
            borderRadius: "0 10px 10px 0",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.30)",
            backgroundColor: "#fff",
          }}
        >
          Back
        </Button>
      </div>
      <Box display="flex" flexDirection="column" gap="16px">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          borderRadius="8px"
          marginLeft="16px"
          marginRight="16px"
          padding="16px"
        >
          {/* <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Typography variant="h5" gutterBottom>
              {mode === "edit"
                ? "Edit Survey"
                : mode === "create"
                  ? "Create Survey"
                  : "Viewing Survey"}
            </Typography>
          </Box> */}
          <Box
            paddingLeft="24px"
            paddingRight="24px"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="16px"
          >
            <Typography align="right" color="text.secondary" paddingRight="0">
              {mode === "view" ? (
                <>
                  Viewing Survey with ID: <br />
                  {surveyId}
                </>
              ) : mode === "create" ? (
                <>
                  Creating Survey with ID: <br />
                  {surveyId}
                </>
              ) : (
                <>
                  Editing Survey with ID: <br />
                  {surveyId}
                </>
              )}
            </Typography>
            {mode === "view" ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Edit />}
                onClick={() => navigate(`./edit`)}
              >
                Edit
              </Button>
            ) : null}
          </Box>
        </Box>

        <Box marginLeft="16px" marginRight="16px" height="80vh">
          {isLoading ? (
            <div>Loading...</div>
          ) : initialSurveyJSObject ? (
            <FormBuilder
              onSave={handleSave}
              mode={mode}
              surveyJSObject={initialSurveyJSObject}
            />
          ) : null}
        </Box>
      </Box>
    </div>
  );
};
