export enum ReportStatus {
  draft = "draft",
  modification_requested = "modification_requested",
  modification_opted = "modification_opted",
  submitted = "submitted",
  approved = "approved",
  rejected = "rejected",
  escalated = "escalated",
  pending_deletion = "pending_deletion",
}

export const statusTypes: { [key in ReportStatus]: string } = {
  draft: "Draft",
  modification_requested: "Modification Requested",
  modification_opted: "Modification Opted",
  submitted: "Pending",
  approved: "Approved",
  rejected: "Rejected",
  escalated: "Escalated",
  pending_deletion: "Pending Deletion",
};

export const reportColourTypes: {
  [key in ReportStatus]: any;
} = {
  draft: "default",
  modification_requested: "warning",
  modification_opted: "warning",
  submitted: "info",
  approved: "success",
  rejected: "error",
  escalated: "secondary",
  pending_deletion: "error",
};

export const priorityColourTypes: {
  [key: string]: any;
} = {
  "0": "info",
  "1": "success",
  "2": "success",
  "3": "success",
  "4": "warning",
  "5": "warning",
  "6": "warning",
  "7": "error",
  "8": "error",
  "9": "error",
};
