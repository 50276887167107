import ShareIcon from "@mui/icons-material/Share";
import { Button, CircularProgress, Link, Typography } from "@mui/material";
import { APIProvider } from "@vis.gl/react-google-maps";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import AlertCard from "./AlertCard";
import AlertTable from "./AlertTable";
import AlertMap from "./AlertsMap";
import AlertFilters, {
  alertFilterConfig,
  RenderAlertFilterChips,
} from "./Search/AlertFilters";
import SearchBar from "./Search/SearchBar";
import ShareMultipleDialog from "./ShareMultipleDialog";
import { DisplayAlertDrawer } from "./add/DisplayAlert/DisplayAlertDrawer";
import { ALERT_STATUS, crimeGroupedTypes } from "./constants";
import { AlertViewContext } from "./context";
import LoadingModal from "../../common/LoadingModal";
import { useDrawer } from "../../common/SlideoutDrawer/DrawerContext";
import SlideOutDrawer from "../../common/SlideoutDrawer/SlideoutDrawer";
import { useAlertsInfiniteQuery } from "../../common/api-client/loadAlerts";
import { isSmallScreen, mailTo } from "../../common/utils";
import { AuthStateContext } from "../../context";
import { APP_GOOGLE_PLACES_KEY } from "../../environment";
import { ICreator, IUser, IUserPermission } from "../../types";
import { DisabledComponentDialog } from "../DisabledComponent";
import { parseUserPermissions } from "../UserManagement/utils";
import "./alerts.scss";

interface IAlertListProps {
  isDraftsList?: boolean;
}

export const AlertList = ({ isDraftsList = false }: IAlertListProps) => {
  const { currentUser: user } = useContext(AuthStateContext);
  const {
    totalAlerts,
    setTotalAlerts,
    currentItems,
    setCurrentItems,
    updatedItems,
    setUpdatedItems,
    policeServices,
    filtersVisible,
    setFiltersVisible,
  } = useContext(AlertViewContext);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view") || "card";
  const bulletinId = searchParams.get("bulletinId");
  const pinToFront = searchParams.get("pinToFront");
  const compareBulletinId = searchParams.get("compareBulletinId");
  const myAlerts = searchParams.get("myAlerts") === "true";
  const incidentStartDate = searchParams.get("incidentStartDate");
  const incidentEndDate = searchParams.get("incidentEndDate");
  const issuedStartDate = searchParams.get("issuedStartDate");
  const issuedEndDate = searchParams.get("issuedEndDate");
  const bulletinType = searchParams.get("bulletinType");
  const associatedCrime = searchParams.get("associatedCrime");
  const policeService = searchParams.get("policeService");
  const searchText = searchParams.get("searchText");
  const similarTo = searchParams.get("similarTo");
  const bounds = searchParams.get("bounds");
  const creator_org_id = searchParams.get("creator_org_id");

  const setSearchText = (value) => {
    if (value === "") {
      setSearchParams((searchParams) => {
        searchParams.delete("searchText");
        return searchParams;
      });
      return;
    }
    setSearchParams((searchParams) => {
      searchParams.set("searchText", value);
      return searchParams;
    });
  };

  const { currentUser, setCurrentUser } = useContext(AuthStateContext);

  const { openDrawer, closeDrawer, setDrawerWidth } = useDrawer();
  const { PUBLISHED } = ALERT_STATUS;

  const topPageRef = useRef<HTMLInputElement | null>(null);
  const bottomPageRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLInputElement | null>(null);
  const [selectedAlertList, setSelectedAlertList] = useState<number[]>([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const { t } = useTranslation("bulletin");

  const [alertPermissions] = useState(
    currentUser &&
      parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );
  const readOnly = alertPermissions
    ? !alertPermissions.canCreateBulletin
    : false;

  const bulletinRefs = {};
  const {
    data,
    error: alertsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isLoading,
  } = useAlertsInfiniteQuery(
    view === "map" ? null : 25,
    crimeGroupedTypes.find((crime) => crime.crime === associatedCrime) || null,
    bulletinType,
    policeServices.find((service) => service.name === policeService) || null,
    incidentStartDate,
    incidentEndDate,
    issuedStartDate,
    issuedEndDate,
    isDraftsList ? null : searchText,
    isDraftsList ? isDraftsList && !readOnly : null,
    myAlerts ? (currentUser?.id as string) : null,
    similarTo ? parseInt(similarTo) : null,
    bounds ? bounds : null,
    creator_org_id ? creator_org_id : null,
  );

  const [isLoadingAlerts, setIsLoadingAlerts] = useState(isLoading);
  const [isReadOnlyDialogOpen, setIsReadOnlyDialogOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [slideoutWidth, setSlideoutWidth] = useState(
    pinToFront && compareBulletinId ? window.innerWidth * 0.7 : 240,
  );

  if (currentItems.length) {
    currentItems.forEach((bulletin) => {
      bulletinRefs[bulletin.id] = createRef();
    });
  }

  const loadMore = () => {
    if (hasNextPage && currentItems.length < totalAlerts) {
      fetchNextPage();
    }
  };

  const modifySelectedAlertList = (id: number) => {
    if (selectedAlertList.includes(id)) {
      setSelectedAlertList((prevList) =>
        prevList.filter((item) => item !== id),
      );
    } else {
      setSelectedAlertList((prevList) => [...prevList, id]);
    }
  };

  const alertClickHandler = (item, index) => {
    if (!isDraftsList && !isSmallScreen()) {
      let drawerWidth;
      // if pinToFront is true, set compareBulletinId to item.id
      // if pinToFront is false, set bulletinId to item.id
      setSearchParams((searchParams) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (pinToFront) {
          newSearchParams.set("compareBulletinId", item?.id?.toString());
          drawerWidth = window.innerWidth * 0.7;
        } else {
          newSearchParams.set("bulletinId", item?.id?.toString());
          drawerWidth = window.innerWidth * 0.5;
        }
        return newSearchParams;
      });

      openDrawer();
      setDrawerWidth(drawerWidth);
    } else {
      navigate(
        `/rubialert/${isDraftsList ? "drafts" : "alerts"}/${item.id}/single`,
        {
          state: {
            incidentStartDate,
            incidentEndDate,
            issuedStartDate,
            issuedEndDate,
            bulletinType,
            associatedCrime,
            policeServices,
            policeService,
            view,
            filtersVisible,
            index,
            searchText,
          },
        },
      );
    }
  };
  const scrollToTop = () => {
    topPageRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    if (similarTo !== null) {
      const startTime = Date.now();
      setIsLoadingModalOpen(true);
      const result = refetch();
      const endTime = Date.now();
      const timeElapsed = endTime - startTime;
      const remainingTime = Math.max(0, 8000 - timeElapsed);
      result.then(() => {
        setTimeout(() => {
          setIsLoadingModalOpen(false);
        }, remainingTime);
      });
    }
  }, [similarTo]);

  useEffect(() => {
    if (readOnly) {
      setIsReadOnlyDialogOpen(true);
    }
  }, [readOnly]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fetchNextPage();
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      },
    );

    const currentAnchor = bottomPageRef.current;
    if (currentAnchor) {
      observer.observe(currentAnchor);
    }

    return () => {
      if (currentAnchor) {
        observer.unobserve(currentAnchor);
      }
    };
  }, [bottomPageRef.current]);

  useEffect(() => {
    if (updatedItems.length > 0) {
      refetch();
      setUpdatedItems([]);
    }
  }, [updatedItems]);

  useEffect(() => {
    if (myAlerts && currentUser?.id !== undefined) {
      refetch();
    }
    if (!currentUser?.id && user) {
      const setUser: ICreator = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        badge: user.badge,
        id: user.id,
      };
      setCurrentUser(setUser as IUser);
    }
    if (!myAlerts) {
      refetch();
    }
  }, [myAlerts, currentUser?.id]);

  useEffect(() => {
    if (isDraftsList && readOnly) {
      return;
    }
    if (data) {
      const items = data.pages.flatMap((page) => page.items);
      setCurrentItems(items);
      setTotalAlerts(data.pages[0].total_count);
    }
  }, [data]);

  useEffect(() => {
    if (isLoadingAlerts && hasNextPage) {
      loadMore();
    } else if (isLoadingAlerts && !hasNextPage) {
      setIsLoadingAlerts(false);
    }
  }, [isLoadingAlerts, hasNextPage]);

  useEffect(() => {
    if (bulletinId) {
      const alert = currentItems.find(
        (item) => item.id === parseInt(bulletinId),
      );
      if (alert) {
        setSearchParams((searchParams) => {
          searchParams.set("bulletinId", bulletinId);
          return searchParams;
        });
        openDrawer();
      }
      openDrawer();
    } else {
      closeDrawer();
    }
  }, [bulletinId]);

  if (alertsError) {
    toast.error("Error fetching alerts");
  }

  const renderDraftsView = () => {
    return (
      <AlertTable
        alertClickHandler={alertClickHandler}
        loadMore={loadMore}
        isDraftsList={isDraftsList}
        refetch={refetch}
      />
    );
  };

  const renderDisabledComponentDialog = () => {
    return (
      <DisabledComponentDialog
        isDialogOpen={isReadOnlyDialogOpen}
        handleClose={() => {
          navigate(`/rubialert/alerts`);
        }}
        title="You do not have access to create bulletins."
        message={
          <Typography>
            Please contact{" "}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href={mailTo({
                email: "support@tryrubicon.com",
                subject: "Requesting Access to Create Bulletins",
                body: `Hello Rubicon Support Team, 

                  I would like to request access to create bulletins.

                  Thank you. 

                  Sincerely,

                  ${currentUser?.first_name} ${currentUser?.last_name} 
                  ${currentUser?.email}`,
              })}
              color="secondary"
            >
              support@tryrubicon.com
            </Link>{" "}
            to request access.
          </Typography>
        }
      />
    );
  };

  const renderAlertsList = () => {
    return (
      <div className="alert-list">
        {isShareModalOpen && (
          <ShareMultipleDialog
            isShareModalOpenArg={isShareModalOpen}
            setIsShareModalOpenArg={setIsShareModalOpen}
            bulletinIDs={selectedAlertList}
          />
        )}
        <div id="top-page-div" ref={topPageRef}></div>
        <div className="search-bar-container">
          {!isDraftsList && (
            <>
              <SearchBar
                getMoreItems={loadMore}
                onSearch={scrollToTop}
                onClearSearch={scrollToTop}
                searchView={view}
                setSearchView={(value) => {
                  setSearchParams((searchParams) => {
                    searchParams.set("view", value);
                    return searchParams;
                  });
                }}
                defaultView="card"
                searchText={searchText || ""}
                setSearchText={setSearchText}
                currentItems={currentItems}
                setCurrentItems={setCurrentItems}
                filtersVisible={filtersVisible}
                setFiltersVisible={setFiltersVisible}
                totalAlerts={totalAlerts}
                loading={isLoadingAlerts}
                setLoading={setIsLoadingAlerts}
                searchType="alert"
                filterChips={
                  <RenderAlertFilterChips
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    getFilters={(searchParams) =>
                      alertFilterConfig(searchParams, view)
                    }
                  />
                }
                filtersRef={searchBarRef}
                activeViews={["card", "list", "map"]}
              />
              {filtersVisible && (
                <AlertFilters
                  isOpen={filtersVisible}
                  anchorEl={searchBarRef.current}
                  onClose={() => setFiltersVisible(false)}
                />
              )}
            </>
          )}
        </div>
        {selectedAlertList.length > 0 && (
          <div className="ml-auto mr-5">
            <div>
              <Typography variant="body1">
                {t("num_bulletins_selected", {
                  count: selectedAlertList.length,
                })}
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={() => {
                    setIsShareModalOpen(true);
                  }}
                >
                  <ShareIcon />
                  {t("share_alerts")}
                </Button>
                {/* clear button */}
                <Button
                  className="ml-2"
                  variant="outlined"
                  onClick={() => {
                    setSelectedAlertList([]);
                  }}
                >
                  Clear
                </Button>
              </Typography>
            </div>
          </div>
        )}
        {view === "card" && (
          <>
            <div className="alert-items">
              {currentItems?.length > 0 &&
                currentItems.map((bulletin, index) => (
                  <AlertCard
                    key={index}
                    ref={bulletinRefs[bulletin.id]}
                    alertItem={bulletin}
                    index={index}
                    alertClickHandler={() => {
                      alertClickHandler(bulletin, index);
                    }}
                    selectCurrentAlert={() =>
                      modifySelectedAlertList(bulletin.id)
                    }
                    selectedAlertList={selectedAlertList}
                    className={
                      bulletinId
                        ? bulletin.id === parseInt(bulletinId)
                          ? "slideout-alert-selected-card"
                          : ""
                        : ""
                    }
                  />
                ))}
            </div>
            <div ref={bottomPageRef} />
            {(isLoading || currentItems.length < totalAlerts) && (
              <div
                className="loading-more"
                style={{
                  padding: "16px 0",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </>
        )}
        {view === "map" && (
          <>
            <APIProvider apiKey={APP_GOOGLE_PLACES_KEY}>
              <AlertMap
                isLoading={isLoading}
                alerts={currentItems}
                alertClickHandler={alertClickHandler}
                selectedAlertId={bulletinId ? parseInt(bulletinId) : null}
                setSelectedAlertId={(id) => {
                  setSearchParams((searchParams) => {
                    searchParams.set("bulletinId", id.toString());
                    return searchParams;
                  });
                }}
              />
            </APIProvider>
            {/* <div className={styles.loadMoreContainer}>
              <div className="alert-count">
                Loaded {currentItems.length} of {totalAlerts}
              </div>
              <Button
                variant="contained"
                color="primary"
                disabled={isFetchingNextPage || !hasNextPage}
                onClick={() => {
                  loadMore();
                }}
                endIcon={
                  isFetchingNextPage ? <CircularProgress size={20} /> : null
                }
              >
                {isFetchingNextPage ? "Loading..." : "Load More Bulletins"}
              </Button>
            </div> */}
          </>
        )}
        {view === "list" && (
          <AlertTable
            alertClickHandler={alertClickHandler}
            loadMore={loadMore}
            isDraftsList={isDraftsList}
            refetch={refetch}
            selectedAlertId={bulletinId ? parseInt(bulletinId) : null}
          />
        )}
      </div>
    );
  };

  const renderLoadingModal = () => {
    const loadingMessages = [
      "Initializing the AI Engine...",
      "Using AI to Analyze all the bulletins...",
      "Computing the similarity matrix...",
      "Ranking the bulletins...",
    ];

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <LoadingModal
          stages={loadingMessages}
          timingMessage="Finding similar bulletins . . ."
        />
      </div>
    );
  };

  const renderAlertsView = () => {
    return (
      <SlideOutDrawer
        width={slideoutWidth}
        slideoutLocation="right"
        slideoutContent={
          bulletinId ? (
            <DisplayAlertDrawer mode={PUBLISHED} queryNextPage={loadMore} />
          ) : (
            <div></div>
          )
        }
        mainContent={
          isLoadingModalOpen ? renderLoadingModal() : renderAlertsList()
        }
        onDrawerClose={() => {
          setSearchParams((searchParams) => {
            searchParams.delete("bulletinId");
            searchParams.delete("pinToFront");
            searchParams.delete("compareBulletinId");
            return searchParams;
          });
          closeDrawer();
        }}
      />
    );
  };

  if (isDraftsList && readOnly) {
    return renderDisabledComponentDialog();
  } else if (isDraftsList) {
    return renderDraftsView();
  } else {
    return renderAlertsView();
  }
};
