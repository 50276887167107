import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Chip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import {
  GetSubmissionResponsePortal,
  SubmissionAttachment,
  SubmissionSummary,
} from "../../common/api-client/or-api";
import { formatDateShort } from "../../common/utils";
import { reportColourTypes, statusTypes } from "../../types/typesReportV2";
import {
  fetchSubmissionData,
  SubmissionData,
  parseMediaDetails,
} from "../../utils/submissionUtils";
import { OnlineReportingContext } from "../online-reporting/context";
import { getTitleFromSubmission } from "../online-reporting/utils";

// Status color mapping for different registry states
const statusColorMapping = {
  Active: "#77DD77", // Green for active entries
  Archived: "#AEC6CF", // Blue for archived
  Flagged: "#FF6961", // Red for flagged items
  "Under Review": "#FFFACD", // Yellow for items under review
};

const RegistryCard = ({
  index,
  registryItem,
  onCardClick,
}: {
  index: number;
  registryItem: SubmissionSummary;
  onCardClick: (item: SubmissionSummary) => void;
}) => {
  const [submissionData, setSubmissionData] = useState<SubmissionData>({
    details: null,
    attachments: [],
  });
  const [loading, setLoading] = useState(true);
  const { getSubmissionForID } = useContext(OnlineReportingContext);

  useEffect(() => {
    const loadSubmissionData = async () => {
      try {
        const data = await fetchSubmissionData(
          registryItem.id,
          getSubmissionForID,
        );
        setSubmissionData(data);
      } finally {
        setLoading(false);
      }
    };

    loadSubmissionData();
  }, [registryItem.id, getSubmissionForID]);

  const mediaDetails = parseMediaDetails(submissionData.attachments);

  return (
    <Card
      onClick={() => onCardClick(registryItem)}
      sx={{
        cursor: "pointer",
        "&:hover": { boxShadow: 6 },
      }}
    >
      {mediaDetails.length > 0 && (
        <Grid container spacing={1} sx={{ p: 1 }}>
          {mediaDetails.map((media, idx) => (
            <Grid item xs={mediaDetails.length === 1 ? 12 : 6} key={idx}>
              <CardMedia
                component={media.media_type === "video" ? "video" : "img"}
                src={media.url}
                sx={{
                  height: mediaDetails.length === 1 ? 200 : 100,
                  objectFit: "cover",
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <CardContent>
        <Typography variant="h6" gutterBottom>
          {submissionData.details &&
            getTitleFromSubmission(
              submissionData.details,
              registryItem.survey_name ?? "",
            )}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {registryItem.survey_name}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Submitted by: {registryItem.first_name} {registryItem.last_name}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Date: {formatDateShort(registryItem.time_created)}
        </Typography>

        {registryItem.tracking_number && (
          <Typography variant="body2" color="text.secondary">
            Tracking #: {registryItem.tracking_number}
          </Typography>
        )}
        <Chip
          sx={{
            mt: 1,
          }}
          style={{
            opacity:
              registryItem.status === "rejected" ||
              registryItem.status === "approved"
                ? 0.6
                : 1,
          }}
          label={
            statusTypes[registryItem.status]
              ? statusTypes[registryItem.status]
              : registryItem.status
          }
          color={
            reportColourTypes[registryItem.status]
              ? reportColourTypes[registryItem.status]
              : "default"
          }
        />
      </CardContent>
    </Card>
  );
};

export default RegistryCard;
