import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Drawer,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Divider,
  Button,
  TextField,
  Chip,
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

import {
  SubmissionSummary,
  SubmissionAttachment,
  GetSubmissionResponsePortal,
} from "../../common/api-client/or-api";
import { API } from "../../common/api-client/or-api/api";
import { formatDateShort } from "../../common/utils";
import { reportColourTypes, statusTypes } from "../../types/typesReportV2";
import {
  fetchSubmissionData,
  parseMediaDetails,
  type MediaDetail,
} from "../../utils/submissionUtils";
import { OnlineReportingContext } from "../online-reporting/context";
import {
  getTitleFromSubmission,
  createCondensedModel,
} from "../online-reporting/utils";

interface RegistryDetailsPanelProps {
  open: boolean;
  onClose: () => void;
  registryItem?: SubmissionSummary;
  loading?: boolean;
}

const RegistryDetailsPanel: React.FC<RegistryDetailsPanelProps> = ({
  open,
  onClose,
  registryItem,
  loading: initialLoading = false,
}) => {
  const [loading, setLoading] = useState(initialLoading);
  const [fullSubmission, setFullSubmission] =
    useState<GetSubmissionResponsePortal | null>(null);
  const [attachments, setAttachments] = useState<SubmissionAttachment[]>([]);
  const [mediaDetails, setMediaDetails] = useState<MediaDetail[]>([]);
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { getSubmissionForID } = useContext(OnlineReportingContext);
  const [notesModified, setNotesModified] = useState(false);
  const [saveNotesLoading, setSaveNotesLoading] = useState(false);
  const notesRef = useRef<HTMLInputElement>();
  const [surveyModel, setSurveyModel] = useState<Model>();

  useEffect(() => {
    const loadSubmissionData = async () => {
      if (!registryItem?.id) return;

      setLoading(true);
      try {
        const { details, attachments } = await fetchSubmissionData(
          registryItem.id,
          getSubmissionForID,
        );
        setFullSubmission(details);
        setAttachments(attachments);
        setMediaDetails(parseMediaDetails(attachments));

        // Create condensed survey model
        const survey = await API.survey.get(details.survey_id);
        const { newSurvey } = await createCondensedModel(
          survey.survey_pdf,
          details.submission,
          attachments,
        );
        setSurveyModel(newSurvey);
      } catch (error) {
        console.error("Error loading submission data:", error);
      }
      setLoading(false);
    };

    loadSubmissionData();
  }, [registryItem?.id, getSubmissionForID]);

  const updateNotes = async () => {
    if (!registryItem?.id) return;

    setSaveNotesLoading(true);
    try {
      await API.submission.patchPortal(registryItem.id, {
        notes: notesRef?.current?.value ?? "",
      });

      setFullSubmission((prev) => ({
        ...prev!,
        notes: notesRef?.current?.value ?? "",
      }));

      setNotesModified(false);
      toast.success("Notes updated successfully");
    } catch (error) {
      toast.error("Failed to update notes, please try again later");
    }
    setSaveNotesLoading(false);
  };

  if (!registryItem && !loading) return null;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "40%",
          minWidth: "400px",
          maxWidth: "800px",
        },
      }}
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">Registry Entry Details</Typography>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 2 }} />

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <CircularProgress />
          </Box>
        ) : registryItem ? (
          <Box
            sx={{
              overflowY: "auto",
              flexGrow: 1,
              mb: 2,
            }}
          >
            {/* Internal Notes Section */}
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              Internal Private Notes
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                multiline
                minRows={4}
                inputRef={notesRef}
                defaultValue={fullSubmission?.notes ?? ""}
                onChange={() => {
                  if (!notesModified) {
                    setNotesModified(true);
                  }
                }}
                variant="outlined"
              />
              <LoadingButton
                variant="contained"
                color="info"
                size="medium"
                fullWidth
                sx={{ mt: 2 }}
                onClick={updateNotes}
                loading={saveNotesLoading}
                disabled={!notesModified}
              >
                Save Changes
              </LoadingButton>
            </Box>

            {/* Status Information */}
            <Box sx={{ mb: 3 }}>
              <Typography
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <strong>Status:</strong>{" "}
                <Chip
                  style={{
                    opacity:
                      registryItem.status === "rejected" ||
                      registryItem.status === "approved"
                        ? 0.6
                        : 1,
                  }}
                  label={
                    statusTypes[registryItem.status]
                      ? statusTypes[registryItem.status]
                      : registryItem.status
                  }
                  color={
                    reportColourTypes[registryItem.status]
                      ? reportColourTypes[registryItem.status]
                      : "default"
                  }
                />
              </Typography>
            </Box>

            <Typography variant="body2" color="text.secondary">
              {registryItem.survey_name}
            </Typography>

            {/* Title */}
            {fullSubmission && (
              <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                {getTitleFromSubmission(
                  fullSubmission,
                  registryItem.survey_name ?? "",
                )}
              </Typography>
            )}

            {/* Condensed Survey Form */}
            {surveyModel && (
              <Box sx={{ mb: 3 }}>
                <div className="condensed-report-wrapper">
                  <Survey model={surveyModel} />
                </div>
              </Box>
            )}

            {/* Media Details */}
            {mediaDetails.length > 0 && (
              <>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Media Details
                </Typography>
                <Box sx={{ mb: 3 }}>
                  {mediaDetails.map((detail) => (
                    <Box key={detail.url} sx={{ mb: 2 }}>
                      {detail.media_type.startsWith("image") ? (
                        <img
                          src={detail.url}
                          alt="Submission attachment"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      ) : detail.media_type.startsWith("video/") ? (
                        <video
                          controls
                          style={{ maxWidth: "100%", height: "auto" }}
                        >
                          <source src={detail.url} type={detail.media_type} />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <Typography>
                          <strong>{detail.url}</strong> - {detail.media_type}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Box>
        ) : null}

        {!loading && registryItem && (
          <Box
            sx={{
              pt: 2,
              borderTop: 1,
              borderColor: "divider",
              mt: "auto",
              backgroundColor: "background.paper",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              onClick={() =>
                navigate(
                  `/rubiregistry/${orgId}/${registryItem.survey_class}/${registryItem.id}`,
                )
              }
            >
              View Full Entry
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default RegistryDetailsPanel;
