import { $convertToMarkdownString } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Add, Cancel, Delete, Edit, Save } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import LexicalEditor, { customTransformers } from "../../common/LexicalEditor";
import {
  DeleteOrgTemplatesRequest,
  PatchOrgTemplatesRequest,
  PutOrgTemplatesRequest,
  Template,
} from "../../common/api-client/or-api";
import { API } from "../../common/api-client/or-api/api";
import { AuthStateContext } from "../../context";

const EmailTemplateConfig = () => {
  const [templates, setTemplates] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<
    | (Template & {
        isAddNew?: boolean;
      })
    | null
  >(null);
  const [isEditing, setIsEditing] = useState(false);
  const [originalEmailBody, setOriginalEmailBody] = useState("");
  const [emailBody, setEmailBody] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const [templateName, setTemplateName] = useState("");
  const [originalTemplateName, setOriginalTemplateName] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { currentUser, getOrgIDFromURL } = useContext(AuthStateContext);

  useEffect(() => {
    async function getTemplates() {
      const response = await API.orgConfig.getTemplate(
        getOrgIDFromURL() ?? currentUser?.rubi_org_id ?? "",
      );
      setTemplates(response.templates);
      console.log("templates", templates);
    }
    getTemplates();
  }, []);

  useEffect(() => {
    if (isSaving) {
      if (
        templateName === originalTemplateName &&
        emailBody === originalEmailBody
      ) {
        toast.info("No changes to save");
        setIsSaving(false);
        return;
      } else if (!templateName || !emailBody) {
        toast.error("Template name and email body cannot be empty");
        setIsSaving(false);
        return;
      } else if (
        templates.some(
          (template) =>
            template.name === templateName &&
            template.name !== originalTemplateName,
        )
      ) {
        toast.error("A template with this name already exists");
        setIsSaving(false);
        return;
      }

      if (selectedTemplate?.isAddNew) {
        handlePut()
          .then((response) => {
            setTemplates(response.templates);
            setIsEditing(false);
            setOriginalTemplateName(templateName);
            setOriginalEmailBody(emailBody);
            setIsSaving(false);
          })
          .catch((error) => {
            console.error("Failed to save changes", error);
            setIsSaving(false);
          });
      } else {
        handlePatch()
          .then((response) => {
            setTemplates(response.templates);
            setIsEditing(false);
            setOriginalTemplateName(templateName);
            setOriginalEmailBody(emailBody);
            setIsSaving(false);
          })
          .catch((error) => {
            console.error("Failed to save changes", error);
            setIsSaving(false);
          });
      }
    }
  }, [isSaving]);

  const handleTemplateChange = (
    _event: React.SyntheticEvent,
    newValue:
      | (Template & {
          isAddNew?: boolean;
        })
      | null,
  ) => {
    if (newValue?.isAddNew) {
      addTemplate();
      return;
    }
    setSelectedTemplate(newValue);
    setTemplateName(newValue?.name || "");
    setEmailBody(newValue?.plain_text || "");
    setOriginalTemplateName(newValue?.name || "");
    setOriginalEmailBody(newValue?.plain_text || "");
    setIsEditing(false);
  };

  const handleTemplateNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTemplateName(event.target.value);
  };

  const startEditing = () => {
    setIsEditing(true);
  };

  const handlePut = async () => {
    const body: PutOrgTemplatesRequest = {
      template: {
        name: templateName.trim(),
        plain_text: emailBody,
      },
    };

    const response = await API.orgConfig.putTemplate(
      getOrgIDFromURL() ?? currentUser?.rubi_org_id ?? "",
      body,
    );
    return response;
  };

  const handlePatch = async () => {
    const body: PatchOrgTemplatesRequest = {
      template: {
        name: templateName.trim(),
        plain_text: emailBody,
      },
    };

    const response = await API.orgConfig.patchTemplate(
      getOrgIDFromURL() ?? currentUser?.rubi_org_id ?? "",
      body,
    );
    return response;
  };

  // const saveChanges = async () => {
  //   try {
  //     const response = selectedTemplate?.isAddNew
  //       ? await handlePut()
  //       : await handlePatch();
  //     setTemplates(response.templates);
  //     setIsEditing(false);
  //     setOriginalTemplateName(templateName);
  //     setOriginalEmailBody(emailBody);
  //   } catch (error) {
  //     console.error("Failed to save changes", error);
  //   }
  // };

  const cancelEditing = () => {
    setTemplateName(originalTemplateName);
    setEmailBody(originalEmailBody);
    setIsEditing(false);
  };

  const addTemplate = () => {
    setSelectedTemplate({ name: "", plain_text: "", isAddNew: true });
    setTemplateName("");
    setEmailBody("");
    setOriginalTemplateName("");
    setOriginalEmailBody("");
    setIsEditing(true);
  };

  const handleDelete = async () => {
    try {
      const body: DeleteOrgTemplatesRequest = {
        template_name: templateName,
      };
      await API.orgConfig.deleteTemplate(
        getOrgIDFromURL() ?? currentUser?.rubi_org_id ?? "",
        body,
      );
      setTemplates((prevTemplates) =>
        prevTemplates.filter((template) => template.name !== templateName),
      );
      setSelectedTemplate(null);
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error("Failed to delete template", error);
    }
  };

  const SaveButton = () => {
    const [editor] = useLexicalComposerContext();
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          editor.update(() => {
            const markdown = $convertToMarkdownString(
              customTransformers,
              undefined,
              true,
            );
            console.log(markdown);
            setEmailBody(markdown);
            setIsSaving(true);
          });
        }}
        startIcon={<Save />}
        disabled={isSaving}
      >
        Save
      </Button>
    );
  };

  const CancelSaveButton = () => {
    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          cancelEditing();
        }}
        startIcon={<Cancel />}
      >
        Cancel
      </Button>
    );
  };

  return (
    <>
      <Typography variant="h6">
        <b>Configure Email Templates</b>
      </Typography>

      <FormControl fullWidth>
        <Autocomplete
          id="email-template"
          options={[
            ...templates,
            { name: "Add New Template", emailBody: "", isAddNew: true },
          ]}
          getOptionLabel={(option) => option.name}
          value={selectedTemplate}
          onChange={handleTemplateChange}
          renderOption={(props, option) => (
            <li
              {...props}
              style={{ fontStyle: option.isAddNew ? "italic" : "normal" }}
            >
              {option.isAddNew ? (
                <>
                  <Add fontSize="small" style={{ marginRight: 8 }} />
                  {option.name}
                </>
              ) : (
                option.name
              )}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Email Template" variant="outlined" />
          )}
        />
      </FormControl>

      {selectedTemplate && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Template Name"
            value={templateName}
            onChange={handleTemplateNameChange}
            sx={{
              marginTop: "20px",
              "& input": {
                cursor: isEditing ? "text" : "default",
              },
            }}
            InputProps={{
              readOnly: !isEditing,
            }}
            error={templates.some(
              (template) =>
                template.name === templateName &&
                template.name !== originalTemplateName,
            )}
            helperText={
              templates.some(
                (template) =>
                  template.name === templateName.trim() &&
                  template.name !== originalTemplateName,
              )
                ? "A template with this name already exists"
                : ""
            }
            disabled={!selectedTemplate.isAddNew}
          />
          <LexicalEditor
            markdown={emailBody}
            readOnly={!isEditing}
            editorButtons={
              isEditing
                ? [<CancelSaveButton key="cancel" />, <SaveButton key="save" />]
                : [
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setOpenDeleteDialog(true)}
                      startIcon={<Delete />}
                      key={"delete"}
                    >
                      Delete
                    </Button>,
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={startEditing}
                      startIcon={<Edit />}
                      key={"edit"}
                    >
                      Edit
                    </Button>,
                  ]
            }
          />
        </div>
      )}

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this template? This action cannot be
            undone and will delete the template for all users.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailTemplateConfig;
