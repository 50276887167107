import { $convertToMarkdownString } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  ArrowBack,
  ArrowUpward,
  AutoFixHighRounded,
  Block,
  CheckCircleOutline,
  CloudDownload,
  ContentCopy,
  Info,
  OpenInNew,
  Share,
  Summarize,
  Transform,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";

import AssignedToSelector from "./AssignedToSelector";
import { AttachmentSlider } from "./AttachmentSlider";
import styles from "./CondensedReportView.module.scss";
import LinkSupplementaryDropdown from "./LinkSupplementaryDropdown";
import RenderCondensedReport from "./RenderCondensedReport";
import ShareReportDialog from "./ShareReportDialog";
import { getReportTimeDisplay, getReportTitle, isReportClosed } from "./utils";
import { useDevEnvironment } from "../../../DevContext";
import BottomFixedActionContainer from "../../../common/BottomFixedActionContainer";
import LexicalEditor, {
  customTransformers,
} from "../../../common/LexicalEditor";
import { RubiModal } from "../../../common/RubiModal";
import TopFixedActionContainer from "../../../common/TopFixedActionContainer";
import { PostSubmissionSupplementalStatusRequest } from "../../../common/api-client/camera-api";
import {
  GetSubmissionResponsePortal,
  GetSurveyResponse,
  LastUpdatedBy,
  OrgConfigModel,
  PatchSubmissionResponse,
  SubmissionAttachment,
  SubmissionPortal,
  SubmissionSummary,
  SubmissionVersionTime,
  Template,
  Unsettable_String,
} from "../../../common/api-client/or-api";
import { API } from "../../../common/api-client/or-api/api";
import { getServiceConfig } from "../../../common/serviceConfigurations";
import { formatDateShort } from "../../../common/utils";
import { AuthStateContext } from "../../../context";
import { reportColourTypes, statusTypes } from "../../../types/typesReportV2";
import { downloadSurveyPDF, generateSurveyPDFBlob } from "../PDFGenerator";
import { OnlineReportingContext } from "../context";
import { registerAutocomplete } from "../custom-questions/AddressAutocompleteQuestion";
import { registerCameraRegistryMap } from "../custom-questions/CameraRegistrySJS/CameraRegistryQuestion";
import { extractLocaleStringFromField } from "../form-management/utils";
import {
  ReportAttachment,
  createCondensedModel,
  imageUrlToBase64,
  uploadToS3,
} from "../utils";

const CondensedReportView = ({
  cisoDisplay = false,
  idOverrride = "",
}: {
  cisoDisplay: boolean;
  idOverrride?: string;
}) => {
  const navigate = useNavigate();

  const { isDevEnv } = useDevEnvironment();
  const { currentUser, getOrgIDFromURL } = useContext(AuthStateContext);

  const [reportMetadata, setReportMetadata] = useState(
    {} as GetSubmissionResponsePortal,
  );

  const [
    childrenSupplementaryReportSurveyJS,
    setChildrenSupplementaryReportSurveyJS,
  ] = useState<any[]>([]);

  const [
    childrenSupplementaryReportsMetadata,
    setChildrenSupplementaryReportsMetadata,
  ] = useState<SubmissionPortal[]>([]);

  const {
    reports,
    getSubmissionForID,
    getSurveyForID,
    fetchLatestReports,
    rv2Users,
    fetchRv2Users,
  } = useContext(OnlineReportingContext);

  const appName = window.location.pathname.split("/")[1];
  const { id, orgId } = useParams();
  // idToUse is the report id
  const idToUse = idOverrride || id;

  const incidentNumberRef = useRef<HTMLInputElement>();
  const priorityRef = useRef<HTMLInputElement>();
  const [tags, setTags] = useState<string[]>([]);

  const additionalNotesRef = useRef<HTMLInputElement>();
  const [notes, setNotes] = useState<{
    approve: string;
    reject: string;
    escalate: string;
  }>({
    approve: "",
    reject: "",
    escalate: "",
  });
  const synopsisRef = useRef<HTMLInputElement>();

  const [modified, setModified] = useState(false);
  const [saveDetailsLoading, setSaveDetailsLoading] = useState(false);
  const [generatePDFLoading, setGeneratePDFLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [specifiedReason, setSpecifiedReason] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isSlideShowActive, setIsSlideShowActive] = useState(false);

  const [emailTemplates, setEmailTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  );

  const [toggleTooltip, setToggleTooltip] = useState(false);

  // these are used for the print pdf function
  const [supplementaryPDFStructure, setSupplementaryPDFStructure] = useState<
    { [key: string]: any }[]
  >([]);
  const [supplementaryAttachments, setSupplementaryAttachments] = useState<
    Array<Array<SubmissionAttachment>>
  >([]);

  // Supplementary Reports
  // We can put the below pieces into context
  const [supplementaryDataLoaded, setSupplementaryDataLoaded] = useState(false);
  const [supplementarySummaryData, setSupplementarySummaryData] = useState<
    GetSubmissionResponsePortal[]
  >([]);
  const [supplementarySurveyStructure, setSupplementarySurveyStructure] =
    useState<{ [key: string]: any }[]>([]);
  const [supplementarySurvey, setSupplementarySurvey] = useState<any>([{}]);
  const [supplementarySurveyOneVersion, setSupplementarySurveyOneVersion] =
    useState<string[]>([]);
  const [supplementaryVersionHistory, setSupplementaryVersionHistory] =
    useState<SubmissionVersionTime[][]>([]);
  const [supplementaryIncidentNumber, setSupplementaryIncidentNumber] =
    useState<string[]>([]);
  const [supplementaryAdditionalNotes, setSupplementaryAdditionalNotes] =
    useState<string[]>([]);
  const [supplementaryLastUpdatedBy, setSupplementaryLastUpdatedBy] = useState<
    (LastUpdatedBy | null)[]
  >([]);
  const [isSupplementaryReport, setIsSupplementaryReport] = useState(false);
  const [supplementaryBase64Data, setSupplementaryBase64Data] = useState<
    { [key: string]: string }[]
  >([]);
  const [supplementaryCondensedSurvey, setSupplementaryCondensedSurvey] =
    useState<Model[]>([]);
  const [supplementaryReportAttachments, setSupplementaryReportAttachments] =
    useState<Array<ReportAttachment>[]>([]);
  const [selectedSupplementaryVersions, setSelectedSupplementaryVersions] =
    useState<string[]>([]);

  // report linking
  const [selectedReportToLink, setSelectedReportToLink] =
    useState<SubmissionSummary | null>(null);
  const [isLinkingDialogOpen, setIsLinkingDialogOpen] = useState(false);
  const [incidentNumberDialogOpen, setIncidentNumberDialogOpen] =
    useState(false);
  const [reportList, setReportList] = useState<SubmissionSummary[]>([]);
  const [isNoLinkFoundDialogOpen, setIsNoLinkFoundDialogOpen] = useState(false);
  const [
    convertGeneralToSupplementaryDialogOpen,
    setConvertGeneralToSupplementaryDialogOpen,
  ] = useState(false);
  const [
    unlinkAllSupplementaryReportsDialogOpen,
    setUnlinkAllSupplementaryReportsDialogOpen,
  ] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  // const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isBtnInfoDialogOpen, setIsBtnInfoDialogOpen] = useState(false);

  const [isGeneratingSynopsis, setIsGeneratingSynopsis] = useState(false);

  const generateSynopsis = async () => {
    setIsGeneratingSynopsis(true);
    const response = await API.submission.getSubmissionSynopsisRequestPortal(
      idToUse || "",
    );
    setReportMetadata({
      ...reportMetadata,
      synopsis: response.synopsis,
    });
    setIsGeneratingSynopsis(false);
    setModified(true);
  };

  const updateAssignee = async (user_id: string | null) => {
    if (!user_id) {
      await API.submission
        .patchPortal(idToUse ?? "", {
          assigned_to: {
            action: Unsettable_String.action.UNSET,
          },
        })
        .then((result: PatchSubmissionResponse) => {
          setReportMetadata({
            ...reportMetadata,
            assigned_to: null,
          });
        })
        .catch((error) => {
          toast.error(
            "Failed to unassign report. Please contact support@tryrubicon.com for assistance.",
          );
        });
    } else {
      // Assign the report
      await API.submission
        .patchPortal(idToUse ?? "", {
          assigned_to: {
            value: user_id,
            action: Unsettable_String.action.SET,
          },
        })
        .then((result: PatchSubmissionResponse) => {
          setReportMetadata({
            ...reportMetadata,
            assigned_to: result.assigned_to,
          });
        })
        .catch((error) => {
          toast.error(
            "Failed to assign report. Please contact support@tryrubicon.com for assistance.",
          );
        });
    }
  };

  const organizationConfig = getServiceConfig(
    orgId ?? currentUser?.rubi_org_id ?? "NA",
    isDevEnv,
  );

  const isClosed = isReportClosed(reportMetadata);

  const [serviceConfig, setServiceConfig] = useState<OrgConfigModel | null>(
    null,
  );
  const priorityOptions = serviceConfig?.metadata.priority_options ?? [];
  const tagOptions = serviceConfig?.metadata.tag_options ?? [""];

  useEffect(() => {
    setSelectedReportToLink(null);
    setIsLinkingDialogOpen(false);
    setIsNoLinkFoundDialogOpen(false);
    setConvertGeneralToSupplementaryDialogOpen(false);
  }, [idToUse]);

  useEffect(() => {
    async function getConfig() {
      const response = await API.orgConfig.get1(orgId ?? "");
      setServiceConfig(response);
    }
    getConfig();
  }, []);

  useEffect(() => {
    async function getTemplates() {
      const response = await API.orgConfig.getTemplate(
        getOrgIDFromURL() ?? currentUser?.rubi_org_id ?? "",
      );
      setEmailTemplates(response.templates);
    }
    getTemplates();
  }, [idToUse]);

  useEffect(() => {
    async function fetchData() {
      await fetchLatestReports!(
        orgId ?? "",
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        100,
      );

      if (isDevEnv) {
        console.log(
          await API.submission.getSubmissionXmlGenerationTestPortal(
            idToUse ?? "",
          ),
        );
      }
      await fetchRv2Users(orgId ?? "");
    }
    fetchData();
  }, [idToUse]);

  useEffect(() => {
    const nonSupplementalReports =
      reports?.filter(
        (report) => report.id !== idToUse && report.is_supplemental === false,
      ) || [];
    setReportList(nonSupplementalReports);
  }, [reports, idToUse]);

  useEffect(() => {
    if (isSubmitting) {
      if (
        modalDetails[selectedAction]?.detailsRequired &&
        specifiedReason === ""
      ) {
        toast.error(
          "Please provide a reason for the action before submitting.",
        );
        setIsSubmitting(false);
        return;
      }
      updateStatus(selectedAction)
        .then(() => {
          setModalOpen(false);
          setSpecifiedReason("");
          setIsSubmitting(false);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    }
  }, [isSubmitting, idToUse]);

  const [surveyResult, setSurveyResult] = useState<GetSurveyResponse>(
    {} as any,
  );
  useEffect(() => {
    async function getReportData() {
      if (getSubmissionForID && getSurveyForID) {
        const fetchedReportMetadata = await getSubmissionForID(idToUse ?? "");
        const fetchedSurveyResult = await getSurveyForID(
          fetchedReportMetadata?.survey_id ?? "",
        );
        setSurveyResult(fetchedSurveyResult ?? ({} as GetSurveyResponse));
        setIsSupplementaryReport(
          fetchedReportMetadata?.is_supplemental ?? false,
        );
        const localSurvey = new Model(fetchedSurveyResult?.survey);
        const submission = fetchedReportMetadata?.submission;
        localSurvey.data = submission;
        // setReportSurveyJS(submission);
        setReportMetadata(
          fetchedReportMetadata ?? ({} as GetSubmissionResponsePortal),
        );
        setChildrenSupplementaryReportsMetadata(
          fetchedReportMetadata?.supplementary_submissions ?? [],
        );

        // setSurveyModel(localSurvey);
      }
    }
    registerAutocomplete();
    registerCameraRegistryMap();
    getReportData();
  }, [idToUse]);

  useEffect(() => {
    if (incidentNumberRef?.current) {
      incidentNumberRef.current.value = reportMetadata?.incident_number ?? "";
    }

    if (additionalNotesRef?.current) {
      additionalNotesRef.current.value = reportMetadata?.notes ?? "";
    }

    if (priorityRef?.current) {
      priorityRef.current.value = reportMetadata?.priority?.toString() ?? "0";
    }

    if (synopsisRef?.current) {
      synopsisRef.current.value = reportMetadata?.synopsis ?? "";
    }

    setTags(reportMetadata?.tags?.split(",").map((tag) => tag.trim()) ?? []);
    setNotes({
      approve: reportMetadata?.approve_note ?? "",
      reject: reportMetadata?.reject_note ?? "",
      escalate: reportMetadata?.escalate_note ?? "",
    });
  }, [reportMetadata, idToUse]);

  const [supplementarySurveyResult, setSupplementarySurveyResult] = useState(
    {} as GetSurveyResponse[],
  );
  useEffect(() => {
    async function getSupplementaryReportData() {
      if (
        !getSurveyForID ||
        !getSubmissionForID ||
        !childrenSupplementaryReportsMetadata
      ) {
        return;
      }

      childrenSupplementaryReportsMetadata.sort(
        (a, b) =>
          new Date(b.time_created).getTime() -
          new Date(a.time_created).getTime(),
      );

      const supplementaryDataPromises =
        childrenSupplementaryReportsMetadata.map(
          async (childSupplementaryReport) => {
            const result = await getSubmissionForID(
              childSupplementaryReport.submission_id,
            );
            const surveyResult = await getSurveyForID(
              childSupplementaryReport.survey_id || "",
            );
            return {
              result,
              surveyResult,
            };
          },
        );

      const supplementaryData = await Promise.all(supplementaryDataPromises);

      // We're collecting all the supplementary data here
      if (supplementaryData.length > 0) {
        const processSupplementaryData: Promise<{
          newSupplementaryAttachments: Array<Array<SubmissionAttachment>>;
          newSupplementarySurveyOneVersion: string[];
          newSupplementaryVersionHistory: SubmissionVersionTime[][];
          newSupplementarySurveyStructure: { [key: string]: any }[];
          newSupplementaryPDFStructure: { [key: string]: any }[];
          newSupplementarySurvey: Model[];
          newSupplementaryReport: SubmissionPortal[];
          newSupplementarySummaryData: GetSubmissionResponsePortal[];
          newSupplementaryIncidentNumber: string[];
          newSupplementaryAdditionalNotes: string[];
          newSupplementaryLastUpdatedBy: (LastUpdatedBy | null)[];
        }> = new Promise((resolve) => {
          const newSupplementaryAttachments: Array<
            Array<SubmissionAttachment>
          > = [];
          const newSupplementarySurveyOneVersion: string[] = [];
          const newSupplementaryVersionHistory: SubmissionVersionTime[][] = [];
          const newSupplementarySurveyStructure: { [key: string]: any }[] = [];
          const newSupplementaryPDFStructure: { [key: string]: any }[] = [];
          const newSupplementarySurvey: Model[] = [];
          const newSupplementaryReport: SubmissionPortal[] = [];
          const newSupplementarySummaryData: GetSubmissionResponsePortal[] = [];
          const newSupplementaryIncidentNumber: string[] = [];
          const newSupplementaryAdditionalNotes: string[] = [];
          const newSupplementaryLastUpdatedBy: (LastUpdatedBy | null)[] = [];

          supplementaryData.forEach((data) => {
            // Important note - don't parse data here, that's done in the condensedModel function
            const suppBaseSurveyStructure = data.surveyResult?.survey ?? {};
            newSupplementaryAttachments.push(data.result?.attachments ?? []);
            newSupplementarySurveyOneVersion.push(
              data.result?.latest_version?.toString() ?? "",
            );
            newSupplementaryVersionHistory.push(
              data.result?.version_times ?? [],
            );
            newSupplementarySurveyStructure.push(suppBaseSurveyStructure ?? {});
            newSupplementaryPDFStructure.push(
              data.surveyResult?.survey_pdf ?? {},
            );
            const newSuppSurvey = new Model(suppBaseSurveyStructure ?? {});

            const submission = data.result?.submission ?? {};

            submission.supplementary_reportedDateTime =
              data.result?.time_created;
            submission.supplementary_linkedDateTime = data.result?.time_updated;
            submission.supplementary_linkedByName = data.result?.last_updated_by
              ?.first_name
              ? data.result?.last_updated_by?.first_name +
                " " +
                data.result?.last_updated_by?.last_name
              : undefined;
            submission.supplementary_linkedByBadge =
              data.result?.last_updated_by?.badge_number ?? undefined;
            newSupplementarySurvey.push(newSuppSurvey);
            newSupplementaryReport.push(submission);
            newSupplementarySummaryData.push(
              data.result ?? ({} as GetSubmissionResponsePortal),
            );
            newSupplementaryIncidentNumber.push(
              data.result?.incident_number ?? "",
            );
            newSupplementaryAdditionalNotes.push(data.result?.notes ?? "");
            newSupplementaryLastUpdatedBy.push(
              data.result?.last_updated_by ?? null,
            );
          });

          resolve({
            newSupplementaryAttachments,
            newSupplementarySurveyOneVersion,
            newSupplementaryVersionHistory,
            newSupplementarySurveyStructure,
            newSupplementaryPDFStructure,
            newSupplementarySurvey,
            newSupplementaryReport,
            newSupplementarySummaryData,
            newSupplementaryIncidentNumber,
            newSupplementaryAdditionalNotes,
            newSupplementaryLastUpdatedBy,
          });
        });

        await processSupplementaryData.then((processedData) => {
          setSupplementaryAttachments(
            processedData.newSupplementaryAttachments,
          );
          setSupplementarySurveyOneVersion(
            processedData.newSupplementarySurveyOneVersion,
          );
          setSupplementaryVersionHistory(
            processedData.newSupplementaryVersionHistory,
          );
          setSupplementarySurveyStructure(
            processedData.newSupplementarySurveyStructure,
          );
          setSupplementaryPDFStructure(
            processedData.newSupplementaryPDFStructure,
          );
          setSupplementarySurvey(processedData.newSupplementarySurvey);
          setChildrenSupplementaryReportSurveyJS(
            processedData.newSupplementaryReport,
          );
          setSupplementarySummaryData(
            processedData.newSupplementarySummaryData,
          );
          setSupplementaryIncidentNumber(
            processedData.newSupplementaryIncidentNumber,
          );
          setSupplementaryAdditionalNotes(
            processedData.newSupplementaryAdditionalNotes,
          );
          setSupplementaryLastUpdatedBy(
            processedData.newSupplementaryLastUpdatedBy,
          );
        });
      }
    }

    getSupplementaryReportData();
  }, [
    childrenSupplementaryReportsMetadata,
    getSurveyForID,
    getSubmissionForID,
    idToUse,
  ]);

  useEffect(() => {
    if (
      supplementaryAttachments.length ===
        childrenSupplementaryReportsMetadata.length &&
      supplementarySurveyOneVersion.length ===
        childrenSupplementaryReportsMetadata.length &&
      supplementaryVersionHistory.length ===
        childrenSupplementaryReportsMetadata.length &&
      supplementarySurveyStructure.length ===
        childrenSupplementaryReportsMetadata.length &&
      supplementaryPDFStructure.length ===
        childrenSupplementaryReportsMetadata.length &&
      supplementarySurvey.length ===
        childrenSupplementaryReportsMetadata.length &&
      childrenSupplementaryReportSurveyJS.length ===
        childrenSupplementaryReportsMetadata.length &&
      supplementarySummaryData.length ===
        childrenSupplementaryReportsMetadata.length &&
      supplementaryIncidentNumber.length ===
        childrenSupplementaryReportsMetadata.length &&
      supplementaryAdditionalNotes.length ===
        childrenSupplementaryReportsMetadata.length &&
      supplementaryLastUpdatedBy.length ===
        childrenSupplementaryReportsMetadata.length
    ) {
      setSupplementaryDataLoaded(true);
    }
  }, [
    supplementaryAttachments,
    supplementarySurveyOneVersion,
    supplementaryVersionHistory,
    supplementarySurveyStructure,
    supplementaryPDFStructure,
    supplementarySurvey,
    childrenSupplementaryReportSurveyJS,
    supplementarySummaryData,
    supplementaryIncidentNumber,
    supplementaryAdditionalNotes,
    childrenSupplementaryReportsMetadata,
    supplementaryLastUpdatedBy,
    idToUse,
  ]);

  useEffect(() => {
    async function load() {
      const tempData: { [key: string]: string }[] = [];
      for (const attachmentList of supplementaryAttachments) {
        const tempDataList: { [key: string]: string } = {};
        for (const attachment of attachmentList) {
          if (
            attachment &&
            attachment.presigned_get &&
            attachment.content_type.includes("image")
          ) {
            const base64 = await imageUrlToBase64(
              attachment.presigned_get.s3_presigned.uri,
            );
            tempDataList[attachment.filename] = base64;
          }
        }
        tempData.push(tempDataList);
      }
      setSupplementaryBase64Data(tempData);
    }
    load();
  }, [supplementaryAttachments, idToUse]);

  useEffect(() => {
    if (
      supplementaryPDFStructure.length <
      childrenSupplementaryReportsMetadata.length
    )
      return;

    async function getData() {
      for (
        let index = 0;
        index < childrenSupplementaryReportsMetadata.length;
        index++
      ) {
        const {
          newSurvey: newSuppSurvey,
          reportAttachments: suppReportAttachments,
        } = await createCondensedModel(
          supplementaryPDFStructure[index],
          childrenSupplementaryReportSurveyJS[index],
          supplementaryAttachments[index],
          true,
          false,
          !isDevEnv,
        );

        supplementaryCondensedSurvey.push(newSuppSurvey);
        supplementaryReportAttachments.push(suppReportAttachments);
      }
    }
    getData();
  }, [
    supplementaryPDFStructure,
    childrenSupplementaryReportSurveyJS,
    supplementaryAttachments,
    childrenSupplementaryReportsMetadata,
    idToUse,
  ]);

  function goBack() {
    if (modified) {
      const result = confirm(
        "Your detail changes have not been submitted. Are you sure you want to leave?",
      );
      if (result) {
        navigate(`/${appName}/${orgId}/reports`);
      }
    } else {
      navigate(`/${appName}/${orgId}/reports`);
    }
  }

  window.onbeforeunload = () => {
    if (modified) {
      return "Your detail changes have not been submitted. Are you sure you want to leave?";
    }
  };
  //   const blocker = useBlocker(() => modified);

  //   useEffect(() => {
  //     if (blocker.state === "blocked") {
  //       const result = confirm("Your detail changes have not been submitted. Are you sure you want to leave?")
  //       if (result) {
  //         blocker.proceed()
  //       }
  //       blocker.reset()
  //     }
  //   }, [blocker, blocker.state]);

  function actionSelected(action: string) {
    setSelectedAction(action);

    if (
      !serviceConfig?.metadata.automated_incident_number_generation &&
      !incidentNumberRef.current?.value &&
      (action === "approved" || action === "escalated")
    ) {
      setIncidentNumberDialogOpen(true);
      return;
    }

    setModalOpen(true);
  }

  async function linkReport(selectedReport: SubmissionSummary) {
    try {
      const originalReportId = selectedReport.id;
      const payload: PostSubmissionSupplementalStatusRequest = {
        is_supplemental: true,
        original_id: originalReportId,
      };
      await API.submission
        .updateStatusPortal1(idToUse ?? "", payload)
        .then(() => {
          toast.success("Report linked successfully");
          navigate(`/${appName}/${orgId}/report/${idToUse}/condensed`, {
            replace: true,
          });
        });
    } catch (error) {
      console.error("Error while linking report", error);
    }
  }

  async function unlinkReport(reportSubmissionId: string) {
    try {
      const payload: PostSubmissionSupplementalStatusRequest = {
        is_supplemental: true,
        original_id: null,
      };
      await API.submission
        .updateStatusPortal1(reportSubmissionId, payload)
        .then(() => {
          toast.success("Report unlinked successfully");
          navigate(
            `/${appName}/${orgId}/report/${reportSubmissionId}/condensed`,
            { replace: true },
          );
        });
    } catch (error) {
      console.error("Error while unlinking report ", error);
    }
  }

  async function convertToGeneralReport() {
    try {
      const payload: PostSubmissionSupplementalStatusRequest = {
        is_supplemental: false,
      };
      await API.submission
        .updateStatusPortal1(idToUse ?? "", payload)
        .then(() => {
          toast.success("Report converted successfully");
          window.location.reload();
        });
    } catch (error) {
      console.error("Error while converting report", error);
    }
  }

  async function convertToSupplementaryReport() {
    try {
      const payload: PostSubmissionSupplementalStatusRequest = {
        is_supplemental: true,
      };
      await API.submission
        .updateStatusPortal1(idToUse ?? "", payload)
        .then(() => {
          toast.success("Report converted successfully");
          window.location.reload();
        });
    } catch (error) {
      console.error("Error while converting report", error);
    }
  }

  const downloadPDF = async () => {
    setGeneratePDFLoading(true);
    await downloadSurveyPDF(organizationConfig?.fullName ?? "");
    setGeneratePDFLoading(false);
  };

  interface IModalDetails {
    title: string;
    description: string | JSX.Element;
    detailsRequired: boolean;
    detailsText: string;
    rteOptionalText?: string;
  }

  const modalDetails: { [key: string]: IModalDetails } = {
    edit: {
      title: "Edit Report",
      description: "Edit the report to fix any mistakes and add information.",
      detailsRequired: false,
      detailsText: "",
    },
    modification_requested: {
      title: "Request Changes",
      description: (
        <>
          <b>Keep the report open</b> and ask for follow-up information from the
          reporting party. Mark as “Modification Requested” and provide a link
          for the reporting party to add their changes. Once they respond, it
          will be marked as “Pending.”
        </>
      ),
      detailsRequired: true,
      detailsText: "Details to provide to the individual.",
      rteOptionalText:
        "The report link to add changes will be automatically generated and attached to the email.",
    },
    approved: {
      title: "Approve Report",
      description: (
        <>
          <b>Close this report</b> with no immediate follow-up required. Mark as
          “Approved” and notify the reporting party.
        </>
      ),
      detailsRequired: false,
      detailsText:
        "Explain any actions taken and any next steps (if any) for the reporting party.",
    },
    rejected: {
      title: "Reject Report",
      description: (
        <>
          <b>Close the report</b> if it is not a police matter or cannot be
          processed. Mark as “Rejected” and notify the reporting party{" "}
        </>
      ),
      detailsRequired: true,
      detailsText: "Details to provide to the individual.",
    },
    escalated: {
      title: "Escalate Report",
      description: (
        <>
          <b>Close the report</b> with police timely follow-up required. Mark as
          “Escalated” and notify the reporting individual.
        </>
      ),
      detailsRequired: true,
      detailsText:
        "Explain what kind of follow-up the reporting party should expect and when.",
    },
  };

  async function updateSupplementarySurveyOne(
    submissionVersion: string,
    supplementaryId: string,
  ) {
    const index = childrenSupplementaryReportsMetadata.findIndex(
      (childReport) => childReport.submission_id === supplementaryId,
    );
    if (index === -1) return;

    // Update selected version state
    setSelectedSupplementaryVersions((prevVersions) => {
      const newVersions = [...prevVersions];
      newVersions[index] = submissionVersion;
      return newVersions;
    });

    if (getSubmissionForID) {
      const result = await getSubmissionForID(
        supplementaryId,
        submissionVersion,
      );
      const submission = result?.submission;

      setSupplementarySurvey((prevSurveys) => {
        const newSurveys = [...prevSurveys];
        const localSurvey = new Model(supplementarySurveyStructure[index]);
        localSurvey.data = submission;
        newSurveys[index] = localSurvey;
        return newSurveys;
      });

      setChildrenSupplementaryReportSurveyJS((prevReports) => {
        const newReports = [...prevReports];
        newReports[index] = submission;
        return newReports;
      });

      setSupplementarySummaryData((prevSummary) => {
        const newSummary = [...prevSummary];
        newSummary[index] = result ?? ({} as GetSubmissionResponsePortal);
        return newSummary;
      });

      setSupplementaryIncidentNumber((prevIncidentNumbers) => {
        const newIncidentNumbers = [...prevIncidentNumbers];
        newIncidentNumbers[index] = result?.incident_number ?? "";
        return newIncidentNumbers;
      });

      setSupplementaryAdditionalNotes((prevNotes) => {
        const newNotes = [...prevNotes];
        newNotes[index] = result?.notes ?? "";
        return newNotes;
      });

      setSupplementaryLastUpdatedBy((prevLastUpdatedBy) => {
        const newLastUpdatedBy = [...prevLastUpdatedBy];
        newLastUpdatedBy[index] = result?.last_updated_by ?? null;
        return newLastUpdatedBy;
      });

      setSupplementaryAttachments((prevAttachments) => {
        const newAttachments = [...prevAttachments];
        newAttachments[index] = result?.attachments ?? [];
        return newAttachments;
      });

      const {
        newSurvey: newSuppSurvey,
        reportAttachments: suppReportAttachments,
      } = await createCondensedModel(
        supplementaryPDFStructure[index],
        submission,
        result?.attachments ?? [],
        true,
        true,
        !isDevEnv,
      );

      setSupplementaryReportAttachments((prevReportAttachments) => {
        const newReportAttachments = [...prevReportAttachments];
        newReportAttachments[index] = suppReportAttachments;
        return newReportAttachments;
      });

      setSupplementaryCondensedSurvey((prevCondensedSurveys) => {
        const newCondensedSurveys = [...prevCondensedSurveys];
        newCondensedSurveys[index] = newSuppSurvey;
        return newCondensedSurveys;
      });

      const tempData: { [key: string]: string } = {};
      for (const attachment of result?.attachments ?? []) {
        if (
          attachment &&
          attachment.presigned_get &&
          attachment.content_type.includes("image")
        ) {
          const base64 = await imageUrlToBase64(
            attachment.presigned_get.s3_presigned.uri,
          );
          tempData[attachment.filename] = base64;
        }
      }

      setSupplementaryBase64Data((prevBase64Data) => {
        const newBase64Data = [...prevBase64Data];
        newBase64Data[index] = tempData;
        return newBase64Data;
      });

      setSupplementaryVersionHistory((prevVersionHistory) => {
        const newVersionHistory = [...prevVersionHistory];
        newVersionHistory[index] = result?.version_times ?? [];
        return newVersionHistory;
      });

      setSupplementarySurveyStructure((prevSurveyStructure) => {
        const newSurveyStructure = [...prevSurveyStructure];
        newSurveyStructure[index] = supplementarySurveyStructure[index];
        return newSurveyStructure;
      });

      setSupplementaryPDFStructure((prevPDFStructure) => {
        const newPDFStructure = [...prevPDFStructure];
        newPDFStructure[index] = supplementaryPDFStructure[index];
        return newPDFStructure;
      });

      setChildrenSupplementaryReportsMetadata((prevMetadata) => {
        const newMetadata = [...prevMetadata];
        newMetadata[index] = {
          ...newMetadata[index],
          last_updated_by: result?.last_updated_by,
          time_created: result?.time_created ?? "",
          time_updated: result?.time_updated ?? "",
        };
        return newMetadata;
      });
    }
  }

  async function updateIncidentNumberAndNotes() {
    setSaveDetailsLoading(true);
    console.log("update incident");
    let updatedData = reportMetadata;
    try {
      await API.submission.patchPortal(idToUse ?? "", {
        incident_number: incidentNumberRef?.current?.value ?? "",
        notes: additionalNotesRef?.current?.value ?? "",
        priority: priorityRef?.current?.value
          ? parseInt(priorityRef.current.value)
          : null,
        synopsis: synopsisRef?.current?.value ?? "",
        tags: tags.join(","),
      });
      updatedData = {
        ...reportMetadata,
        notes: additionalNotesRef?.current?.value ?? "",
        incident_number: incidentNumberRef.current?.value ?? "",
        priority: priorityRef?.current?.value
          ? parseInt(priorityRef.current.value)
          : null,
        synopsis: synopsisRef?.current?.value ?? "",
        tags: tags.join(","),
      };
      setReportMetadata(updatedData);
      toast.success("Details updated successfully");
    } catch (error) {
      toast.error("Failed to update details, please try again later");
    }
    setModified(false);
    setSaveDetailsLoading(false);
    return updatedData;
  }

  async function updateStatus(status: string) {
    try {
      let newMetadata: GetSubmissionResponsePortal = {
        ...reportMetadata,
      };

      if (!isClosed && modified) {
        const updatedMetadata = await updateIncidentNumberAndNotes();
        newMetadata = updatedMetadata;
      }

      newMetadata = {
        ...newMetadata,
        status: status,
        last_updated_by: {
          ...currentUser,
          badge_number: currentUser?.badge_number ?? currentUser?.badge ?? "",
        } as LastUpdatedBy,
      };

      if (status === "approved") {
        newMetadata.approve_note = specifiedReason;
      } else if (status === "rejected") {
        newMetadata.reject_note = specifiedReason;
      } else if (status === "escalated") {
        newMetadata.escalate_note = specifiedReason;
      }

      if (
        serviceConfig?.metadata.automated_incident_number_generation &&
        serviceConfig?.metadata.incident_generation_outcomes?.includes(status)
      ) {
        try {
          const incidentNumberResponse = await API.submission.generateIncident(
            idToUse ?? "",
          );

          newMetadata = {
            ...newMetadata,
            incident_number: incidentNumberResponse.incident_number,
          };
        } catch (error) {
          console.error("Incident number generation failed: ", error);
        }
      }

      setReportMetadata(newMetadata);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        const pdfBlob = await generateSurveyPDFBlob(
          organizationConfig?.fullName ?? "",
        );
        const pdfFile = new File(
          [pdfBlob],
          `${
            organizationConfig?.fullName
          }-Online Report-${new Date().toISOString()}.pdf`,
          { type: "application/pdf" },
        );

        const s3Details = await uploadToS3(pdfFile, idToUse ?? "", true);

        await API.submission.patchPortal(idToUse ?? "", {
          pdf: s3Details.id,
        });
      } catch (error) {
        console.error("Error while generating PDF", error);
      }
      if (status === "approved") {
        await API.submission.patchPortal(
          idToUse ?? "",
          specifiedReason ? { approve_note: specifiedReason } : {},
        );
      } else if (status === "rejected") {
        await API.submission.patchPortal(
          idToUse ?? "",
          specifiedReason ? { reject_note: specifiedReason } : {},
        );
      } else if (status === "modification_requested") {
        await API.submission.patchPortal(
          idToUse ?? "",
          specifiedReason ? { modification_request_note: specifiedReason } : {},
        );
      } else if (status === "escalated") {
        await API.submission.patchPortal(
          idToUse ?? "",
          specifiedReason ? { escalate_note: specifiedReason } : {},
        );
      }
      await API.submission
        .updateStatusPortal(idToUse ?? "", {
          status: status,
        })
        .then((res) => {
          setReportMetadata({
            ...newMetadata,
            assigned_to: res.assigned_to,
            status: status,
          });
        });

      toast.success(
        "Report updated successfully. Email has been sent to the reporting party.",
      );
    } catch (error) {
      toast.error("Failed to update status, please try again later");
    }
  }

  const updateTags = (event, newTags: string[]) => {
    setModified(true);
    setTags(newTags);
  };

  if (!(reportMetadata && reportMetadata.status)) {
    return (
      <div style={{ margin: "60px auto" }}>
        <CircularProgress />
      </div>
    );
  }

  const shareReportButton = () => {
    return (
      <Tooltip title="Share Report" arrow>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setIsShareDialogOpen(true)}
          endIcon={<Share />}
        >
          Share Report
        </Button>
      </Tooltip>
    );
  };

  const downloadPDFButton = () => {
    return (
      <Tooltip title="Download Report" arrow>
        <Fab
          onClick={downloadPDF}
          color="secondary"
          size="small"
          aria-label="Download Report"
        >
          <CloudDownload />
        </Fab>
      </Tooltip>
    );
  };

  const convertToSupplementaryReportButton = () => {
    return (
      <Button
        variant="contained"
        color="info"
        size="medium"
        onClick={() => {
          if (childrenSupplementaryReportsMetadata.length > 0) {
            setUnlinkAllSupplementaryReportsDialogOpen(true);
          } else {
            setConvertGeneralToSupplementaryDialogOpen(true);
          }
        }}
        endIcon={<Transform />}
      >
        Convert to Supplementary Report
      </Button>
    );
  };

  const convertToGeneralReportButton = () => {
    return [
      <Button
        variant="contained"
        color="info"
        size="medium"
        onClick={() => {
          setIsNoLinkFoundDialogOpen(true);
        }}
        endIcon={<Transform />}
        key={"convert"}
      >
        Convert to General Report
      </Button>,
    ];
  };

  const reportListButton = () => {
    return (
      <LinkSupplementaryDropdown
        reportList={reportList}
        selectedReportToLink={selectedReportToLink}
        setSelectedReportToLink={setSelectedReportToLink}
        setIsLinkingDialogOpen={setIsLinkingDialogOpen}
      />
    );
  };

  const modifyButton = () => {
    return (
      <Tooltip title={modalDetails.modification_requested.description}>
        <Button
          variant="contained"
          color="warning"
          size="medium"
          onClick={() => actionSelected("modification_requested")}
          endIcon={<Summarize />}
        >
          Request Changes
        </Button>
      </Tooltip>
    );
  };

  const approveButton = () => {
    return (
      <Tooltip title={modalDetails.approved.description}>
        <Button
          variant="contained"
          color="success"
          size="medium"
          onClick={() => actionSelected("approved")}
          endIcon={<CheckCircleOutline />}
        >
          Approve
        </Button>
      </Tooltip>
    );
  };

  const rejectButton = () => {
    return (
      <Tooltip title={modalDetails.rejected.description}>
        <Button
          variant="contained"
          color="error"
          size="medium"
          onClick={() => actionSelected("rejected")}
          endIcon={<Block />}
        >
          Reject
        </Button>
      </Tooltip>
    );
  };

  const escalateButton = () => {
    return (
      <Tooltip title={modalDetails.escalated.description}>
        <Button
          variant="contained"
          color={reportColourTypes["escalated"]}
          size="medium"
          onClick={() => actionSelected("escalated")}
          endIcon={<ArrowUpward />}
        >
          Escalate
        </Button>
      </Tooltip>
    );
  };

  const infoButton = () => {
    return (
      <IconButton
        onClick={() => setIsBtnInfoDialogOpen(true)}
        size="small"
        style={{ marginLeft: "8px" }}
      >
        <Info />
      </IconButton>
    );
  };

  const getButtonGroups = (): JSX.Element[][] => {
    const setLeftButtons = () => {
      if (isSupplementaryReport) {
        return convertToGeneralReportButton();
      }
      const defaultButtons: JSX.Element[] = [];
      if (!isClosed) {
        if (!cisoDisplay) {
          defaultButtons.push(convertToSupplementaryReportButton());
        }
      }
      return defaultButtons;
    };
    const setActionButtons = () => {
      if (isSupplementaryReport) {
        return [reportListButton()];
      }

      if (!isClosed) {
        const defaultActions = [
          modifyButton(),
          approveButton(),
          rejectButton(),
          escalateButton(),
          infoButton(),
        ];

        if (isDevEnv && isSupplementaryReport) {
          return [...defaultActions];
        }

        if (isDevEnv && cisoDisplay) {
          return [approveButton()];
        }
        return defaultActions;
      }
      return [];
    };
    return [setLeftButtons(), setActionButtons()];
  };

  const RenderSupplementaryReportAttachments = ({ index }) => {
    const [supplementaryAttachmentIndex, setSupplementaryAttachmentIndex] =
      useState(-1);
    return (
      <div>
        <Typography
          variant="h6"
          style={{
            marginTop: "20px",
          }}
        >
          Attachments ({supplementaryReportAttachments[index].length})
        </Typography>
        <AttachmentSlider
          reportAttachments={supplementaryReportAttachments[index]}
          setSelectedIndex={setSupplementaryAttachmentIndex}
        />
      </div>
    );
  };

  const SubmitButton = () => {
    const [editor] = useLexicalComposerContext();
    return (
      <Button
        key="submit"
        variant="contained"
        color="primary"
        onClick={() => {
          editor.update(() => {
            const markdown = $convertToMarkdownString(
              customTransformers,
              undefined,
              true,
            );
            setSpecifiedReason(markdown);
            setIsSubmitting(true);
          });
        }}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        disabled={isSubmitting}
      >
        Submit
      </Button>
    );
  };

  const surveyTitle =
    extractLocaleStringFromField(surveyResult.survey?.title) ?? "";

  return (
    <div className={styles.wrapperContainer}>
      {/* Top Bar */}
      <TopFixedActionContainer>
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Chip
            label={
              statusTypes[reportMetadata.status]
                ? statusTypes[reportMetadata.status]
                : reportMetadata.status
            }
            color={
              reportColourTypes[reportMetadata.status]
                ? reportColourTypes[reportMetadata.status]
                : "default"
            }
            size="medium"
          />
          <AssignedToSelector
            currentAssignee={reportMetadata.assigned_to ?? null}
            currentUser={currentUser}
            onAssign={(user_id: string | null) => {
              updateAssignee(user_id);
            }}
            userOptions={rv2Users?.users ?? []}
            disableEdit={isClosed}
          />
          <div style={{ flex: 1 }}>
            <Typography
              variant="inherit"
              sx={{
                textOverflow: "ellipsis",
              }}
            >
              {getReportTitle(isSupplementaryReport, surveyTitle)} - (
              {reportMetadata.first_name} {reportMetadata.last_name})
              {reportMetadata.time_created && (
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  {new Date(reportMetadata.time_created).toLocaleString()}
                </Typography>
              )}
            </Typography>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          {shareReportButton()}
          {downloadPDFButton()}
        </div>
      </TopFixedActionContainer>
      <Button
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<ArrowBack />}
        onClick={goBack}
        style={{
          marginTop: "8px",
          height: "40px",
          position: "fixed",
          zIndex: 2,
          borderRadius: "0 10px 10px 0",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.30)",
          backgroundColor: "#fff",
        }}
      >
        Back
      </Button>
      {/* Record Information */}
      <div>
        <Paper elevation={3} className={styles.paperOfficerDetailsContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3>{!cisoDisplay && <>Police </>}Record Information</h3>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Typography variant="body2">
                <span style={{ fontWeight: 500 }}>Tracking Number:</span>{" "}
                <span>{reportMetadata.tracking_number}</span>
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  const trackingNumber =
                    reportMetadata.tracking_number ||
                    "No tracking number available";
                  navigator.clipboard.writeText(trackingNumber);
                }}
                title="Copy tracking number"
              >
                <ContentCopy fontSize="small" color="primary" />
              </IconButton>
            </div>
          </div>

          {!cisoDisplay && (
            <FormControl fullWidth>
              <TextField
                id="incidentNumber"
                key={reportMetadata?.incident_number}
                inputRef={incidentNumberRef}
                disabled={isClosed}
                variant="outlined"
                label="RMS Incident / Occurrence Number"
                helperText={
                  serviceConfig?.metadata.automated_incident_number_generation
                    ? "Auto-generated if left blank; otherwise, provide a number to override"
                    : ""
                }
                placeholder={
                  serviceConfig?.metadata.automated_incident_number_generation
                    ? `Auto-generated when the record is ${serviceConfig.metadata.incident_generation_outcomes?.join(", ")}`
                    : "Enter Incident Number"
                }
                onChange={() => {
                  if (!modified) {
                    setModified(true);
                  }
                }}
              />
            </FormControl>
          )}
          <FormControl fullWidth>
            <TextField
              id="additionalNotes"
              inputRef={additionalNotesRef}
              disabled={isClosed}
              variant="outlined"
              label="Internal Private Notes"
              multiline
              minRows={4}
              onChange={() => {
                if (!modified) {
                  setModified(true);
                }
              }}
            />
          </FormControl>
          {isClosed && (
            <div>
              <hr />
              {notes.approve ? (
                <div className="my-2">
                  <div>
                    <span style={{ color: "rgba(0, 0, 0, 0.38)" }}>
                      Note attached to Approval Email:
                    </span>
                    <Tooltip title="Sent email response automatically included personalized greeting, reporting tracking number, incident number, and officer details.">
                      <IconButton size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="my-2">
                    <LexicalEditor markdown={notes.approve} readOnly={true} />
                  </div>
                </div>
              ) : (
                reportMetadata.status === "approved" && (
                  <div className="my-4 fw-extra-bold">
                    No Approval Note Provided。
                    <Tooltip title="Sent email response automatically included personalized greeting, reporting tracking number, incident number, and officer details.">
                      <IconButton size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )
              )}
              {notes.reject ? (
                <div className="my-2">
                  <div>
                    <span style={{ color: "rgba(0, 0, 0, 0.38)" }}>
                      Note attached to Rejection Email:
                    </span>
                    <Tooltip title="Sent email response automatically included personalized greeting, reporting tracking number, incident number, and officer details.">
                      <IconButton size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="my-2">
                    <LexicalEditor markdown={notes.reject} readOnly={true} />
                  </div>
                </div>
              ) : (
                reportMetadata.status === "rejected" && (
                  <div className="my-4 fw-extra-bold">
                    Reject without any notes.
                  </div>
                )
              )}
              {notes.escalate ? (
                <div className="my-2">
                  <div>
                    <span style={{ color: "rgba(0, 0, 0, 0.38)" }}>
                      Note attached to Escalation Email:
                    </span>
                    <Tooltip title="Sent response template automatically included personalized greeting, reporting tracking number, incident number, and officer details.">
                      <IconButton size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="my-2">
                    <LexicalEditor markdown={notes.escalate} readOnly={true} />
                  </div>
                </div>
              ) : (
                reportMetadata.status === "escalated" && (
                  <div className="my-4 fw-extra-bold">
                    Escalate without any notes.
                  </div>
                )
              )}
              <div style={{ color: "rgba(0, 0, 0, 0.38)" }}>
                Last Update by {reportMetadata.last_updated_by?.rank_short}{" "}
                {reportMetadata.last_updated_by?.first_name}{" "}
                {reportMetadata.last_updated_by?.last_name} (
                {reportMetadata.last_updated_by?.badge_number}) on{" "}
                {formatDateShort(reportMetadata.time_updated)}
              </div>
              <hr />
            </div>
          )}
          {!cisoDisplay && priorityOptions.length > 0 && (
            <FormControl fullWidth>
              <TextField
                disabled={isClosed}
                variant="outlined"
                label="Priority"
                inputRef={priorityRef}
                onChange={() => {
                  if (!modified) {
                    setModified(true);
                  }
                }}
                defaultValue={reportMetadata?.priority?.toString() ?? "0"}
                select
              >
                {priorityOptions.map((option) => (
                  <MenuItem key={option.name} value={option.value.toString()}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
          <div>
            <Typography variant="h6">
              Synopsis
              <LoadingButton
                variant="contained"
                color="primary"
                size="medium"
                sx={{ margin: 2 }}
                onClick={() => generateSynopsis()}
                loading={isGeneratingSynopsis}
                disabled={isClosed}
                hidden={isClosed}
              >
                {synopsisRef.current?.value ? (
                  <>Regenerate</>
                ) : (
                  <>
                    Generate (BETA) <AutoFixHighRounded />
                  </>
                )}
              </LoadingButton>
            </Typography>
            {!isClosed && (
              <Typography variant="body2">
                <b>
                  For the best synopsis, please correct any errors and add
                  missing details to the report first. The synopsis will be
                  included in the RMS export but won&apos;t be shared with the
                  reporting individual.
                </b>
              </Typography>
            )}
            {isGeneratingSynopsis ? (
              <div
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "4px",
                  padding: "8px",
                  marginTop: "16px",
                }}
              >
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </div>
            ) : (
              <FormControl fullWidth>
                <TextField
                  id="synopsis"
                  disabled={isClosed}
                  variant="outlined"
                  inputRef={synopsisRef}
                  multiline
                  placeholder="No synopsis generated yet."
                  helperText={
                    synopsisRef.current?.value
                      ? "Generated synopsis may contain errors. Please review and update important information."
                      : ""
                  }
                  minRows={4}
                  onChange={() => {
                    if (!modified) {
                      setModified(true);
                    }
                  }}
                />
              </FormControl>
            )}
          </div>
          {isDevEnv && cisoDisplay && (
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                multiple
                disabled={isClosed}
                options={tagOptions}
                id="tags-autocomplete"
                value={tags}
                onChange={updateTags} //handleEmailChange
                filterSelectedOptions
                freeSolo
                loading={false}
                filterOptions={(_options, params) => {
                  const filtered: string[] = [...tagOptions];
                  if (params.inputValue !== "") {
                    filtered.push(params.inputValue);
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder="Add tags..."
                  />
                )}
              />
            </FormControl>
          )}
          <hr />
          {!isClosed && (
            <LoadingButton
              variant="contained"
              color="info"
              size="medium"
              fullWidth
              sx={{ marginBottom: 2 }}
              onClick={() => updateIncidentNumberAndNotes()}
              loading={saveDetailsLoading}
              disabled={modified === false}
            >
              Save Changes
            </LoadingButton>
          )}
        </Paper>
      </div>
      {/* Supplementary Reports Loading */}
      {!supplementaryDataLoaded &&
        childrenSupplementaryReportsMetadata.length > 0 && (
          <div
            style={{
              margin: "60px auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
      {/* Supplementary Reports */}
      {supplementaryDataLoaded &&
        childrenSupplementaryReportsMetadata?.map(
          (supplementaryReport, index) => {
            return (
              <div
                className="condensed-report-wrapper"
                key={supplementaryReport.submission_id}
              >
                <Paper elevation={3} className={styles.paperContainer}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        <h3
                          style={{
                            display: "inline-block",
                            marginRight: "16px",
                          }}
                        >
                          Supplementary Report{" "}
                          {childrenSupplementaryReportsMetadata.length - index}
                        </h3>
                        Version: {supplementaryReport.latest_version} -
                        {`Time Created: ${formatDateShort(
                          supplementaryReport.time_created,
                        )}`}
                      </span>
                      {!["approved", "rejected"].includes(
                        reportMetadata?.status,
                      ) && (
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          sx={{ height: "30px", marginRight: "-8px" }}
                          onClick={() =>
                            unlinkReport(supplementaryReport.submission_id)
                          }
                        >
                          Unlink
                        </Button>
                      )}
                    </div>
                    <Box mt={2}>
                      {supplementaryLastUpdatedBy[index]?.first_name ? (
                        <Typography variant="body2" color="textSecondary">
                          <strong>Linked by: </strong>
                          {`${supplementaryLastUpdatedBy[index]?.first_name} ${supplementaryLastUpdatedBy[index]?.last_name}`}{" "}
                          on{" "}
                          {formatDateShort(
                            childrenSupplementaryReportsMetadata[index]
                              ?.time_created,
                          )}
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          <strong>Autolinked: </strong>
                          According to the referenced report number at the time
                          of submission on{" "}
                          {formatDateShort(
                            childrenSupplementaryReportsMetadata[index]
                              ?.time_created,
                          )}
                        </Typography>
                      )}
                      <Divider sx={{ my: 2 }} />
                    </Box>
                    {supplementaryVersionHistory[index].length > 1 && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <Typography id="demo-simple-select-label">
                              Displaying
                            </Typography>
                            <Select
                              id="demo-simple-select"
                              value={
                                selectedSupplementaryVersions[index] ||
                                supplementarySurveyOneVersion[index]
                              }
                              variant="outlined"
                              onChange={(event) => {
                                updateSupplementarySurveyOne(
                                  event.target.value,
                                  supplementaryReport.submission_id,
                                );
                              }}
                            >
                              {supplementaryVersionHistory[index]
                                ?.sort((a, b) => b.version - a.version)
                                .map((version) => (
                                  <MenuItem
                                    key={`version-${version.version}`}
                                    value={version.version}
                                  >
                                    {version.version ===
                                    supplementaryReport.latest_version ? (
                                      <span>
                                        <strong>Latest Version</strong> -{" "}
                                        {getReportTimeDisplay(
                                          version.time_created,
                                        )}
                                      </span>
                                    ) : (
                                      <span>
                                        Version {version.version} -{" "}
                                        {getReportTimeDisplay(
                                          version.time_created,
                                        )}
                                      </span>
                                    )}
                                  </MenuItem>
                                )) ?? []}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                  <Survey model={supplementaryCondensedSurvey[index]} />
                  {supplementaryReportAttachments[index].length > 0 && (
                    <RenderSupplementaryReportAttachments index={index} />
                  )}
                </Paper>
              </div>
            );
          },
        )}

      {/* {supplementaryDataLoaded &&
        childrenSupplementaryReportsMetadata?.map(
          (supplementaryReportMetadata, index) => {
            return (
              <RenderCondensedReport
                key={supplementaryReportMetadata.submission_id}
                reportMetadata={supplementaryReportMetadata}
                organizationConfig={organizationConfig}
                supplementaryPDFStructure={{} as any}
                childrenSupplementaryReportSurveyJS={
                  childrenSupplementaryReportSurveyJS[index]
                }
                supplementaryAttachments={[] as any}
                setSelectedIndex={setSelectedIndex}
                selectedIndex={selectedIndex}
                setIsSlideShowActive={setIsSlideShowActive}
                isSlideShowActive={isSlideShowActive}
                // surveyResult={supplementarySurveyStructure[index]}
              />
            );
          }
        )} */}
      {/* Condensed Report */}
      <RenderCondensedReport
        reportMetadata={reportMetadata}
        organizationConfig={organizationConfig}
        supplementaryPDFStructure={supplementaryPDFStructure}
        childrenSupplementaryReportSurveyJS={
          childrenSupplementaryReportSurveyJS
        }
        supplementaryAttachments={supplementaryAttachments}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        setIsSlideShowActive={setIsSlideShowActive}
        isSlideShowActive={isSlideShowActive}
        // surveyResult={surveyResult}
      />
      <BottomFixedActionContainer buttonGroups={getButtonGroups()} />
      {/* Modal */}
      <RubiModal isModalOpenArg={modalOpen} setIsModalOpenArg={setModalOpen}>
        <div className={styles.modalContentContainer}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Chip
              color={reportColourTypes[selectedAction]}
              sx={{ marginRight: 1 }}
            />
            <Typography variant="h6">
              {modalDetails[selectedAction]?.title}
            </Typography>
            <IconButton
              onClick={() => setToggleTooltip(!toggleTooltip)}
              size="small"
            >
              <Info />
            </IconButton>
          </Box>
          {toggleTooltip && (
            <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2 }}>
              {modalDetails[selectedAction]?.description}
            </Typography>
          )}

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <Autocomplete
              id="email-template"
              options={emailTemplates}
              getOptionLabel={(option) => option.name}
              value={selectedTemplate}
              onChange={(event, newValue) => {
                setSelectedTemplate(newValue);
                setSpecifiedReason(newValue?.plain_text ?? "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Template"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <LexicalEditor
            markdown={specifiedReason}
            belowEditorChildren={
              <Typography>
                {modalDetails[selectedAction]?.rteOptionalText}
              </Typography>
            }
            aboveEditorChildren={
              <Typography>
                {modalDetails[selectedAction]?.detailsText}
              </Typography>
            }
            editorButtons={[
              <Button
                key="cancel"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setSpecifiedReason("");
                  setModalOpen(false);
                  setSelectedTemplate(null);
                  setIsSubmitting(false);
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>,
              <SubmitButton key="submit" />,
            ]}
          />
        </div>
      </RubiModal>
      <Dialog
        open={isLinkingDialogOpen}
        onClose={() => {
          setIsLinkingDialogOpen(false);
          setSelectedReportToLink(null);
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
          }}
        >
          Confirm Link
        </DialogTitle>
        <DialogContent>
          {selectedReportToLink && (
            <>
              <DialogContentText>
                Please confirm if you want to link this supplementary report to:
              </DialogContentText>
              <br />
              <DialogContentText>
                <b>
                  {selectedReportToLink.survey_name} {" - "}
                  {selectedReportToLink.tracking_number}
                  <br />
                </b>
              </DialogContentText>

              <DialogContentText>
                Submitted by{" "}
                <b>
                  {selectedReportToLink.first_name}{" "}
                  {selectedReportToLink.last_name}
                </b>{" "}
                on {formatDateShort(selectedReportToLink.time_created)}
              </DialogContentText>
              <Link
                href={`/${appName}/${orgId}/report/${selectedReportToLink.id}/condensed`}
                target="_blank"
                rel="noopener"
                underline="always"
                variant="body1"
                color="primary"
                display="flex"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                Click here to open report {selectedReportToLink.tracking_number}{" "}
                in a new tab
                <OpenInNew sx={{ ml: 0.5 }} />
              </Link>
              {selectedReportToLink.status === "modification_requested" && (
                <DialogContentText>
                  <br />
                  ⚠️ Important Notice: Linking this supplementary report will
                  disable any previously sent Information Request links for
                  report {selectedReportToLink.tracking_number}. A notification
                  email will also be sent to the reporting party. If further
                  follow-up information is needed, you will need to send a new
                  Information Request.
                </DialogContentText>
              )}
              {["approved", "rejected"].includes(
                selectedReportToLink.status,
              ) && (
                <DialogContentText>
                  <br />
                  ⚠️ Important Notice: Linking this supplementary report will
                  re-open the previously closed report{" "}
                  {selectedReportToLink.tracking_number}. A notification email
                  will be sent to the reporting party. You will need to
                  re-process report {selectedReportToLink.tracking_number}.
                </DialogContentText>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <Button
            onClick={() => {
              setIsLinkingDialogOpen(false);
              setSelectedReportToLink(null);
            }}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!selectedReportToLink) return;
              linkReport(selectedReportToLink).then(() => {
                navigate(
                  `/${appName}/${orgId}/report/${selectedReportToLink.id}/condensed`,
                  {
                    replace: true,
                  },
                );
                setIsLinkingDialogOpen(false);
              });
            }}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isNoLinkFoundDialogOpen}
        onClose={() => {
          setIsNoLinkFoundDialogOpen(false);
          setSelectedReportToLink(null);
        }}
      >
        <DialogTitle>No Link Found</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you can't link this supplementary report to an original report,
            it will be converted to a standalone report.
          </DialogContentText>
          <DialogContentText>
            <br />
            <b>Are you sure you want to proceed?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <Button
            onClick={() => {
              setIsNoLinkFoundDialogOpen(false);
              setSelectedReportToLink(null);
            }}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              convertToGeneralReport().then(() => {
                setIsNoLinkFoundDialogOpen(false);
              });
            }}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={convertGeneralToSupplementaryDialogOpen}
        onClose={() => {
          setConvertGeneralToSupplementaryDialogOpen(false);
        }}
      >
        <DialogTitle>Converting To Supplementary Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Converting to Supplementary Report will let you link it with another
            report. You can undo this action later.
          </DialogContentText>
          <DialogContentText>
            <br />
            <b>Are you sure you want to proceed?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <Button
            onClick={() => {
              setConvertGeneralToSupplementaryDialogOpen(false);
            }}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              convertToSupplementaryReport().then(() => {
                setConvertGeneralToSupplementaryDialogOpen(false);
              });
            }}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={unlinkAllSupplementaryReportsDialogOpen}
        onClose={() => {
          setUnlinkAllSupplementaryReportsDialogOpen(false);
        }}
      >
        <DialogTitle>Unlink all Supplementary Reports</DialogTitle>
        <DialogContent>
          <DialogContentText>
            In order to convert this report to a Supplementary Report, you need
            to unlink all Supplementary Reports linked to this report.
          </DialogContentText>
          <DialogContentText>
            <br />
            <b>Do you want to unlink all Supplementary Reports at this time?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <Button
            onClick={() => {
              setUnlinkAllSupplementaryReportsDialogOpen(false);
            }}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              const responses = childrenSupplementaryReportsMetadata.map(
                (report) => unlinkReport(report.submission_id),
              );
              Promise.all(responses).then(() => {
                setUnlinkAllSupplementaryReportsDialogOpen(false);
                convertToSupplementaryReport().then(() => {
                  setConvertGeneralToSupplementaryDialogOpen(false);
                });
              });
            }}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isBtnInfoDialogOpen}
        onClose={() => {
          setIsBtnInfoDialogOpen(false);
        }}
      >
        <DialogTitle>Action Button Information</DialogTitle>
        <DialogContent>
          {Object.entries(modalDetails).map(([key, value]) => (
            <div key={key}>
              <Typography variant="h6">{value.title}</Typography>
              <Typography>{value.description}</Typography>
              <br />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsBtnInfoDialogOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={incidentNumberDialogOpen}
        onClose={() => {
          setIncidentNumberDialogOpen(false);
        }}
      >
        <DialogTitle>Incident Number Missing</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close the report without adding one?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIncidentNumberDialogOpen(false);
            }}
            color="primary"
            variant="outlined"
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setIncidentNumberDialogOpen(false);
              // The close report modal
              setModalOpen(true);
            }}
            color="primary"
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              backgroundColor: "#f5f5f5",
              borderRadius: 1,
              p: 1,
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              value={`${window.location.origin}/${appName}/${orgId}/report/${id}/condensed`}
              InputProps={{
                readOnly: true
              }}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 1
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `${window.location.origin}/${appName}/${orgId}/report/${id}/condensed`
                  )
                  .then(() => {
                    toast.success("Link copied to clipboard");
                  });
              }}
              sx={{
                ml: 2,
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                transition: "background-color 0.3s ease",
                ":hover": {
                  backgroundColor: "#3f51b5"
                }
              }}
            >
              <ContentCopy />
            </Button>
          </Box> */}
      <ShareReportDialog
        isShareDialogOpen={isShareDialogOpen}
        setIsShareDialogOpen={setIsShareDialogOpen}
        appName={appName}
        orgId={orgId ?? ""}
        id={idToUse ?? ""}
      />
    </div>
  );
};

export default CondensedReportView;
