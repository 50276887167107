import { Add } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import "./SurveyManagement.scss";

import { useFormBuilderContext } from "./FormContextProvider";
import { extractSurveyTitleFromModel } from "./utils";
import { LinkCell } from "../../../common/DataGridCells";
import { API } from "../../../common/api-client/or-api/api";
import { AuthStateContext } from "../../../context";

// ... keeping useDynamicColumnWidths helper with modified ratios ...
interface ColumnWidths {
  id: number;
  title: number;
  time_created: number;
  status: number;
}

const useDynamicColumnWidths = (
  topPageRef: React.RefObject<HTMLElement>,
): ColumnWidths => {
  const calculateColumnWidths = useCallback(
    (parentWidth: number): ColumnWidths => {
      const baseWidth = parentWidth
        ? parentWidth * 0.85
        : window.innerWidth * 0.85;

      const ratios = {
        id: 2,
        title: 3,
        time_created: 2,
        status: 1.5,
      };
      const totalRatios = Object.values(ratios).reduce(
        (acc, val) => acc + val,
        0,
      );

      const columnWidths: ColumnWidths = {
        id: (baseWidth / totalRatios) * ratios.id,
        title: (baseWidth / totalRatios) * ratios.title,
        time_created: (baseWidth / totalRatios) * ratios.time_created,
        status: (baseWidth / totalRatios) * ratios.status,
      };

      return columnWidths;
    },
    [],
  );

  return calculateColumnWidths(
    topPageRef.current?.parentElement?.offsetWidth ?? 300,
  );
};

const SurveyListView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { currentUser } = useContext(AuthStateContext);
  const orgId = params.orgId;

  const { surveyModels, setSurveyModels } = useFormBuilderContext();

  // ... authentication code stays the same ...

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [surveysResponse] = await Promise.all([
          API.survey.getOrgSurveys(orgId ?? ""),
        ]);
        setSurveyModels(surveysResponse?.surveys || []);
      } catch (error) {
        console.error("Error fetching surveys:", error);
        navigate("/");
      }
    };
    fetchData();
  }, [orgId, navigate]);

  const topPageRef = useRef<HTMLInputElement | null>(null);
  const columnWidths = useDynamicColumnWidths(topPageRef);

  const renderLinkCell = (params) => {
    const buildPath = `/rubireportv2/${orgId}/forms/surveys/${params.row.id}`;
    return (
      <LinkCell
        value={params.value}
        onClick={() =>
          navigate(buildPath, {
            replace: true,
            state: { orgId },
          })
        }
      />
    );
  };

  const createNewSurveyHandler = () => {
    navigate(`new`, {
      replace: true,
      state: { orgId },
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "Survey ID",
      width: columnWidths.id,
      renderCell: (params) => renderLinkCell(params),
    },
    {
      field: "title",
      headerName: "Survey Title",
      width: columnWidths.title,
      valueGetter: (params) => extractSurveyTitleFromModel(params.row),
    },
    {
      field: "time_created",
      headerName: "Created At",
      width: columnWidths.time_created,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "status",
      headerName: "Status",
      width: columnWidths.status,
      valueGetter: (params) => {
        return params.row.status;
      },
    },
  ];

  return (
    <div className="survey_list_container">
      <div id="top-page-div" ref={topPageRef}></div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Survey Management
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={createNewSurveyHandler}
            startIcon={<Add />}
            className="create-survey-button"
          >
            Create Survey
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Surveys
      </Typography>
      <DataGrid
        rows={surveyModels}
        columns={columns}
        rowSelection={false}
        getRowId={(row) => row.id}
        sx={{
          "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
            {
              whiteSpace: "normal",
              wordWrap: "break-word",
            },
        }}
        autoHeight
      />
    </div>
  );
};

export default SurveyListView;
