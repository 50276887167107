/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Unsettable_String = ({
    value: string;
    action: Unsettable_String.action;
} | {
    action: Unsettable_String.action;
});

export namespace Unsettable_String {

    export enum action {
        SET = 'set',
        UNSET = "unset",
    }


}

