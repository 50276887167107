import { CircularProgress, Paper, Typography } from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

import { RegistryContext } from "./context/RegistryContext";
import {
  GetSubmissionResponsePortal,
  GetSurveyResponse,
  SubmissionAttachment,
} from "../../common/api-client/or-api";
import { API } from "../../common/api-client/or-api/api";
import "survey-core/defaultV2.min.css";
import { AuthStateContext } from "../../context";
import { defaultTheme } from "../online-reporting/constants";

interface AttachmentMap {
  [key: string]: string;
}

interface RegistryDetails {
  title?: string;
  tracking_number?: string;
  incident_number?: string;
  last_updated?: string;
}

const RegistryCreate = () => {
  const navigate = useNavigate();
  const { orgId, registryName, entryId } = useParams();
  const { currentUser } = useContext(AuthStateContext);

  const [surveyModel, setSurveyModel] = useState<Model>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [attachmentIDs, setAttachmentIDs] = useState<AttachmentMap>({});
  const { registryForms } = useContext(RegistryContext);
  const [surveyID, setSurveyID] = useState<string>("");
  const [newSubmissionID, setNewSubmissionID] = useState<string>(entryId ?? "");
  const [registryDetails, setRegistryDetails] = useState<RegistryDetails>({});

  // Handle form submission
  const handleSubmit = useCallback(
    async (sender: any, options: any) => {
      options.showSaveInProgress();
      try {
        const dataToSend = JSON.parse(JSON.stringify(sender.data));

        // Clean up base64 image data
        const traverse = (obj: any) => {
          for (const key in obj) {
            if (key === "content" && obj["content"]?.startsWith("data:")) {
              delete obj[key];
            } else if (typeof obj[key] === "object") {
              traverse(obj[key]);
            }
          }
        };
        traverse(dataToSend);

        if (newSubmissionID) {
          // Update existing submission

          if (entryId) {
            await API.submission.updateStatusPortal(newSubmissionID, {
              status: "modification_portal",
            });
          }

          await API.submission.patchPortal(newSubmissionID, {
            version: {
              submission: dataToSend,
              attachments: { ids: Object.values(attachmentIDs) },
            },
            first_name: currentUser?.first_name,
            last_name: currentUser?.last_name,
            email: currentUser?.email,
            phone_number: currentUser?.phone_number,
          });

          await API.submission.updateStatusPortal(newSubmissionID, {
            status: "submitted",
          });
        }

        options.showSaveSuccess();
        navigate(`/rubiregistry/${orgId}/${registryName}`);
      } catch (error: any) {
        console.error("Submission error:", error);
        setError(
          error.message || "An error occurred while saving the submission",
        );
        options.showSaveError();
      }
    },
    [registryName, newSubmissionID, attachmentIDs, navigate],
  );

  // Handle file uploads
  const handleFileUpload = useCallback(
    async (options: any) => {
      const returnValues: any[] = [];
      try {
        for (const file of options.files) {
          // Get presigned URL for upload
          const s3Details = await API.submission.postSubmissionAttachmentPortal(
            newSubmissionID ?? "",
            {
              filename: file.name,
              size: file.size,
              content_type: file.type,
              version_specific: false,
            },
          );

          // Upload to S3
          const headers: { [key: string]: string } = {};
          s3Details.s3_presigned.headers.forEach((header) => {
            headers[header.key] = header.value;
          });

          await axios.put(s3Details.s3_presigned.uri, file, { headers });

          // Update attachment IDs
          setAttachmentIDs((prev) => ({
            ...prev,
            [options.name + file.name]: s3Details.id,
          }));

          // Convert file to base64 for preview
          const content = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(file);
          });

          returnValues.push({
            id: s3Details.id,
            file,
            content,
          });
        }
        options.callback(returnValues);
      } catch (error) {
        console.error("File upload error:", error);
        setError("Failed to upload files");
      }
    },
    [newSubmissionID],
  );

  // Handle file removal
  const handleClearFiles = useCallback(
    async (options: any) => {
      try {
        if (!options.value?.length) {
          return options.callback("success");
        }

        const newAttachmentIDs = { ...attachmentIDs };

        if (options.fileName) {
          const fileToRemove = options.value.find(
            (item: File) => item.name === options.fileName,
          );
          if (fileToRemove) {
            delete newAttachmentIDs[options.name + fileToRemove.name];
          }
        } else {
          options.value.forEach((item: any) => {
            delete newAttachmentIDs[options.name + item.name];
          });
        }

        setAttachmentIDs(newAttachmentIDs);
        options.callback("success");
      } catch (error) {
        console.error("File removal error:", error);
        setError("Failed to remove files");
      }
    },
    [attachmentIDs],
  );

  // Load initial data
  useEffect(() => {
    async function loadData() {
      try {
        let submissionData: GetSubmissionResponsePortal | undefined;
        const registryForm = registryForms.find(
          (form) => form.survey_class === registryName,
        );
        // Get survey structure
        const surveyData = await API.survey.get(registryForm?.survey_id ?? "");
        setSurveyID(registryForm?.survey_id ?? "");

        // If editing, get submission data
        if (newSubmissionID) {
          submissionData = await API.submission.getPortal(
            newSubmissionID,
            "latest",
          );

          // Set registry details
          setRegistryDetails({
            title: registryForm?.name,
            tracking_number: submissionData.tracking_number ?? "",
            incident_number: submissionData.incident_number ?? "",
            last_updated: submissionData.time_updated,
          });

          // Set attachment IDs from existing submission
          const newAttachments: AttachmentMap = {};
          submissionData.attachments?.forEach(
            (attachment: SubmissionAttachment) => {
              newAttachments[attachment.filename] = attachment.id;
            },
          );
          setAttachmentIDs(newAttachments);
        } else {
          const response = await API.submission.putPortal(orgId ?? "", {
            survey_id: registryForm?.survey_id ?? "",
          });
          setNewSubmissionID(response.submission_id);
        }

        // Create and configure survey model
        const model = new Model(surveyData.survey);
        if (submissionData) {
          model.data = submissionData.submission;
        }
        model.applyTheme(defaultTheme);

        setSurveyModel(model);
      } catch (error: any) {
        console.error("Error loading data:", error);
        setError(error.message || "Failed to load form data");
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [registryName, newSubmissionID]);

  // Set up survey event handlers
  useEffect(() => {
    if (!surveyModel) return;

    surveyModel.onComplete.clear();
    surveyModel.onComplete.add(handleSubmit);

    surveyModel.onUploadFiles.clear();
    surveyModel.onUploadFiles.add((_, options) => handleFileUpload(options));

    surveyModel.onClearFiles.clear();
    surveyModel.onClearFiles.add((_, options) => handleClearFiles(options));

    // Scroll to top on page change
    surveyModel.onCurrentPageChanged.clear();
    surveyModel.onCurrentPageChanged.add(() => {
      window.scrollTo(0, 0);
    });
  }, [surveyModel, handleSubmit, handleFileUpload, handleClearFiles]);

  if (isLoading || !surveyModel) {
    return (
      <div style={{ margin: "60px auto", textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ padding: "20px" }}>
      {error && (
        <Paper
          elevation={3}
          style={{
            padding: "16px",
            marginBottom: "20px",
            backgroundColor: "#ffebee",
          }}
        >
          <Typography color="error">{error}</Typography>
        </Paper>
      )}

      <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          {entryId ? "Edit Registry Entry" : "New Registry Entry"}
        </Typography>
        {entryId && (
          <div style={{ marginTop: "10px" }}>
            {registryDetails.title && (
              <Typography variant="body1">
                <strong>Title:</strong> {registryDetails.title}
              </Typography>
            )}
            {registryDetails.tracking_number && (
              <Typography variant="body1">
                <strong>Tracking Number:</strong>{" "}
                {registryDetails.tracking_number}
              </Typography>
            )}
            {registryDetails.incident_number && (
              <Typography variant="body1">
                <strong>Incident Number:</strong>{" "}
                {registryDetails.incident_number}
              </Typography>
            )}
            {registryDetails.last_updated && (
              <Typography variant="body1">
                <strong>Last Updated:</strong>{" "}
                {format(new Date(registryDetails.last_updated), "PPpp")}
              </Typography>
            )}
          </div>
        )}
      </Paper>

      <Paper elevation={3} style={{ padding: "20px" }}>
        <Survey model={surveyModel} />
      </Paper>
    </div>
  );
};

export default RegistryCreate;
