import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import datePickerEN from "./locales/en/bulletin/create/datePicker.json";
import filterEN from "./locales/en/bulletin/filter.json";
import menuEN from "./locales/en/bulletin/menu.json";
import searchbarEN from "./locales/en/bulletin/searchbar.json";
import shareBulletinEN from "./locales/en/bulletin/share.json";
import bulletinEN from "./locales/en/bulletin.json";
import condensedReportEN from "./locales/en/reportsv2/condensed_report.json";
import datePickerFR from "./locales/fr/bulletin/create/datePicker.json";
import filterFR from "./locales/fr/bulletin/filter.json";
import menuFR from "./locales/fr/bulletin/menu.json";
import searchbarFR from "./locales/fr/bulletin/searchbar.json";
import shareBulletinFR from "./locales/fr/bulletin/share.json";
import bulletinFR from "./locales/fr/bulletin.json";
import condensedReportFR from "./locales/fr/reportsv2/condensed_report.json";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      bulletin: bulletinEN,
      "bulletin/searchbar": searchbarEN,
      "bulletin/create/datePicker": datePickerEN,
      "bulletin/share": shareBulletinEN,
      "bulletin/filter": filterEN,
      "bulletin/menu": menuEN,
      "reportsv2/condensed_report": condensedReportEN,
    },
    fr: {
      bulletin: bulletinFR,
      "bulletin/searchbar": searchbarFR,
      "bulletin/share": shareBulletinFR,
      "bulletin/filter": filterFR,
      "bulletin/create/datePicker": datePickerFR,
      "bulletin/menu": menuFR,
      "reportsv2/condensed_report": condensedReportFR,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
