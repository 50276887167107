import html2pdf from "html2pdf.js";
import { useEffect, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

import styles from "./PDFGenerator.module.scss";
import { ReportAttachment, createCondensedModel } from "./utils";
import { SubmissionAttachment } from "../../common/api-client/or-api";

const generatePDF = async (
  serviceName: string,
  download = true,
  supplementaryReports: any = [],
) => {
  const input = document.getElementById("print-target");
  const clone = input?.cloneNode(true) as HTMLElement;
  clone.setAttribute("style", "display: block;");

  supplementaryReports.forEach((report) => {
    const reportElement = document.createElement("div");
    reportElement.innerHTML = report.htmlContent;
    clone.insertBefore(reportElement, clone.firstChild);
  });

  const textAreas = clone!.getElementsByTagName("textarea");
  for (const textarea of textAreas) {
    const content = document.createElement("div");
    content.className = "sd-input sd-comment";
    content.innerText = textarea.value;
    content.style.backgroundColor = "transparent";
    // content.style.border = "1px solid rgba(118, 118, 118, 0.3)";
    content.style.width = "100%";
    content.style.display = "block";

    const parent = textarea.parentElement!.parentElement!;
    parent.className =
      "sd-element--nested sd-element sd-question sd-question--title-top sd-row__question--small sd-question--answered sd-question--disable";
    textarea.parentElement!.appendChild(content);
    textarea.style.display = "none";
    parent.style.border = "1px solid rgba(118, 118, 118, 0.3)";
    parent.style.width = "100%";
    parent.style.display = "block";
  }
  const filename = `${serviceName}-Online Report-${new Date().toISOString()}.pdf`;

  const opt = {
    margin: 0.2,
    filename,
    pagebreak: {
      avoid: [
        "input",
        "textarea",
        ".sd-title",
        ".attachmentImage",
        ".attachmentDescription",
      ],
    },
    enableLinks: true,
    image: { type: "jpeg", quality: 0.75 },
    html2canvas: {
      scale: 2,
    },
    jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
  };

  const pdf = html2pdf()
    .from(clone)
    .set(opt)
    .toPdf()
    .get("pdf")
    .then(async function (pdf) {
      try {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(100);
          pdf.text(
            "Page " + i + " of " + totalPages,
            pdf.internal.pageSize.getWidth() / 2.3,
            pdf.internal.pageSize.getHeight() - 0.1,
          );
          pdf.setTextColor("blue");
          pdf.textWithLink(
            "Powered by Rubicon",
            0.2,
            pdf.internal.pageSize.getHeight() - 0.1,
            { url: "https://tryrubicon.com/" },
          );
        }
      } catch (e) {
        console.log(e);
      }
    });

  if (download) {
    pdf.save();
  } else {
    return pdf.outputPdf("blob", filename);
  }
};

export const downloadSurveyPDF = async (
  serviceName: string,
  supplementaryReports = [],
) => {
  generatePDF(serviceName, true, supplementaryReports);
};

export const generateSurveyPDFBlob = async (
  serviceName: string,
  supplementaryReports = [],
) => {
  return generatePDF(serviceName, false, supplementaryReports);
};

export const PrintPDF = ({
  basePDFStructure,
  report,
  attachments,
  base64Data,
  serviceName,
  serviceLogoURL,
  reportTitle,
  hideAdminInformation = true,
  supplementaryReportsObject,
}: {
  basePDFStructure: any;
  report: any;
  attachments: SubmissionAttachment[];
  base64Data: { [key: string]: string };
  serviceName: string;
  serviceLogoURL: string;
  reportTitle: string;
  hideAdminInformation: boolean;
  supplementaryReportsObject?: {
    supplementaryPDFStructure: any;
    supplementaryReport: any;
    supplementaryAttachments: SubmissionAttachment[][];
  };
}) => {
  const [newSurvey, setNewSurvey] = useState<Model>(new Model());
  const [reportAttachments, setReportAttachments] = useState<
    Array<ReportAttachment>
  >([]);

  // Supplementary reports
  const [newSupplementaryReports, setNewSupplementaryReports] = useState<
    Array<Model>
  >([]);
  const [supplementaryReportAttachments, setSupplementaryReportAttachments] =
    useState<Array<Array<ReportAttachment>>>([]);

  useEffect(() => {
    if (!basePDFStructure.pages || !report || !attachments) return;

    async function getData() {
      const { newSurvey, reportAttachments } = await createCondensedModel(
        basePDFStructure,
        report,
        attachments,
        hideAdminInformation,
        false,
        true,
      );
      setNewSurvey(newSurvey);
      setReportAttachments(reportAttachments);
    }
    getData();
  }, [basePDFStructure, report, attachments, hideAdminInformation]);

  useEffect(() => {
    if (!supplementaryReportsObject) return;

    async function getData() {
      const {
        supplementaryPDFStructure,
        supplementaryReport,
        supplementaryAttachments,
      } = supplementaryReportsObject;

      const newSupplementaryReports: Array<Model> = [];
      const supplementaryReportAttachments: Array<Array<ReportAttachment>> = [];

      Object.values(supplementaryPDFStructure).length > 0 &&
        supplementaryPDFStructure.forEach(async (supplementaryPDF, index) => {
          const { newSurvey, reportAttachments } = await createCondensedModel(
            supplementaryPDF,
            supplementaryReport[index],
            supplementaryAttachments[index],
            hideAdminInformation,
            true,
            true,
          );

          newSupplementaryReports.push(newSurvey);
          supplementaryReportAttachments.push(reportAttachments);
        });

      setNewSupplementaryReports(newSupplementaryReports);
      setSupplementaryReportAttachments(supplementaryReportAttachments);
    }
    getData();
  }, [
    supplementaryReportsObject,
    hideAdminInformation,
    supplementaryReportsObject?.supplementaryPDFStructure,
    supplementaryReportsObject?.supplementaryReport,
    supplementaryReportsObject?.supplementaryAttachments,
  ]);
  const isOpen =
    report.status !== "Approved" &&
    report.status !== "Rejected" &&
    report.status !== "Escalated";
  return (
    <div
      id="print-target"
      style={{ display: "none" }}
      className={styles.printTarget}
    >
      {isOpen && (
        <div className={styles.unofficialHeader}>
          <b>Not Official Until Approved</b>
        </div>
      )}
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <h3>{serviceName}</h3>
          <span>{reportTitle}</span>
        </div>
        <img
          id="service-logo"
          className={styles.pdfServiceLogo}
          src={serviceLogoURL}
          alt="logo"
        />
      </div>
      {newSupplementaryReports.map((supplementaryReport, index) => (
        <div key={index}>
          <h3>Supplementary Report {newSupplementaryReports.length - index}</h3>
          <Survey model={supplementaryReport} />
          {supplementaryReportAttachments[index].length > 0 ? (
            <div>
              <p>Attachments</p>
              {supplementaryReportAttachments[index].map(
                (attachment, index) => {
                  if (!attachment.incident_evidence_upload) return null;

                  const file = attachment.incident_evidence_upload[0];
                  return (
                    <div className={styles.pdfImageContainer} key={index}>
                      <p>
                        <b>File name: </b>
                        {file.name}
                      </p>
                      {file.type.includes("image") && (
                        <img
                          className="attachmentImage"
                          src={base64Data[file.name]}
                          alt={file.name}
                        />
                      )}
                      <p className="attachmentDescription">
                        {attachment.incident_evidence_description}
                      </p>
                      <hr />
                    </div>
                  );
                },
              )}
            </div>
          ) : (
            <p>No Attachments Present</p>
          )}
        </div>
      ))}
      <div>
        <h3>Original Report</h3>
      </div>
      <Survey model={newSurvey} />
      {reportAttachments.length > 0 ? (
        <div>
          <p>Attachments</p>
          {reportAttachments.map((attachment, index) => {
            if (!attachment.incident_evidence_upload) return null;

            const file = attachment.incident_evidence_upload[0];
            return (
              <div className={styles.pdfImageContainer} key={index}>
                <p>
                  <b>File name: </b>
                  {file.name}
                </p>
                {file.type.includes("image") && (
                  <img
                    className="attachmentImage"
                    src={base64Data[file.name]}
                    alt={file.name}
                  />
                )}
                <p className="attachmentDescription">
                  {attachment.incident_evidence_description}
                </p>
                <hr />
              </div>
            );
          })}
        </div>
      ) : (
        <p>No Attachments Present</p>
      )}
    </div>
  );
};
